import React from 'react';
import css from './ButtonPagination.module.scss';
import classes from 'classnames';
import { ReactComponent as Arrow } from 'assets/svg/arrow.svg';

interface IButtonPagination {
	className?: string;
	showInstructions?: boolean;
}

export const ButtonPagination: React.FC<IButtonPagination> = props => {
	const { children, className, showInstructions = true } = props;

	return (
		<>
			<div className={classes(className, css.buttonPagination)}>{children}</div>
			{showInstructions && (
				<div className={css.instructions}>
					Clicking “Next” saves your selections or entries. <Arrow></Arrow>
				</div>
			)}
		</>
	);
};
