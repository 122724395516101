import { PageFrame } from 'components/PageFrame/PageFrame';
import 'firebase/auth';
import 'firebase/firestore';
import React from 'react';
import { Link } from 'components/Link/Link';
import css from './HomeView.module.scss';
import { Icon } from 'components/Icon/Icon';
import { Container } from 'components/Container/Container';
import { Section } from 'components/Section/Section';
import classes from 'classnames';

export const HomeView = () => {
	return (
		<PageFrame
			title=''
			fullWidth
			HTMLDescription='Our predictor tool can help estimate the financial impact of the fee-for-outcome arrangements being considered, while simultaneously identifying potential losses, so as to enable earlier, more proactive mitigation.'
		>
			<section className={css.hero}>
				<div className={css.left}>
					<Container>
						<h1 className={css.secondHeading}>
							<div className={classes(css.firstHeading, 'h2')}>
								A holistic view of value based care<span className='screenReaderText'>.</span>
							</div>
							Reimbursement in relation to total
							<span className={css.underlinedBrushStroke}> cost of care</span>.
						</h1>
						<p>
							Our predictor tool can help estimate the financial impact of the fee-for-outcome
							arrangements being considered, while simultaneously identifying potential losses, so as to
							enable earlier, more proactive mitigation.
						</p>
						<p>
							Create your free account to keep track of your assessment history and modify it at a later
							date.
						</p>
						<Link type='primary' to='/signin'>
							Start New Assessment
						</Link>
					</Container>
				</div>
				<div className={css.right}>
					<Container>
						<div className={css.infoPanel}>
							<div className={css.iconWrapper}>
								<Icon className={css.icon} type='Dollar' color='white' size='large'></Icon>
							</div>
							<h2 className={classes('uppercase', 'blue', css.heading)}>Estimate your future savings</h2>
							<p>Try our online tool or get help from experts</p>
						</div>
					</Container>
				</div>
			</section>
			<section className={css.blueBanner}>
				<Container>
					<h2 className={classes(css.heading, 'h3')}>
						There are certain data sources and specific team members that might be able to help get you
						the information you need, learn more below:
					</h2>
				</Container>
			</section>
			<section className={css.iconFeatures}>
				<Container className={css.container}>
					<div className={css.iconFeature}>
						<div className={css.iconWrapper}>
							<Icon className={css.icon} type='Database' size='largest' color='green'></Icon>
						</div>
						<h2 className={classes('h0', css.iconFeaturesHeading, css.blue)}>What data will I need?</h2>
						<ul>
							<li>
								Primary care team roles, salary, &amp; benefits{' '}
								<Icon
									type='Info'
									tooltip='MD/DO
                                    NP/PA
                                    Care Manager
                                    Behavioral Health
                                    Pharmacy
                                    Nursing (RN, LPN, MA)
                                    '
									color='blue'
									className={css.icon}
								></Icon>
							</li>
							<li>
								Other investment costs
								<Icon
									type='Info'
									tooltip='Other EMR capabilities, e.g., virtual/telehealth
                                    Embedded decision support
                                    Data analytics
                                    Align physician compensation plan
                                    Other technology connectivity capabilities
                                    Other CIN expenditures or investments 
                                    '
									color='blue'
									className={css.icon}
								></Icon>
							</li>
							<li>
								System net revenue (Fee-for-service) by payer
								<Icon
									type='Info'
									tooltip='Inpatient
                                    Outpatient
                                    Professional
                                    '
									color='blue'
									className={css.icon}
								></Icon>
							</li>
							<li>
								System net revenue by payer (Pop Health Revenue)
								<Icon
									type='Info'
									tooltip='Care Coordination Fee (PMPM from payer)
                                    Shared Savings
                                    Pay-for-performance (eg. quality measures)
                                    Capitation
                                    '
									color='blue'
									className={css.icon}
								></Icon>
							</li>
							<li>
								Member lives by payer
								{/* <Icon type='Info' tooltip='' color='blue' className={css.icon}></Icon> */}
							</li>
							<li>
								Fee-for-service volume (Revenue &amp; Usage)
								<Icon
									type='Info'
									tooltip='Admissions/Readmissions
                                    ED
                                    Imaging
                                    High cost procedures
                                    '
									color='blue'
									className={css.icon}
								></Icon>
							</li>
						</ul>
					</div>
					<div className={css.iconFeature}>
						<div className={css.iconWrapper}>
							<Icon className={css.icon} type='MultipleUsers' size='largest'></Icon>
						</div>
						<h2 className={classes('h0', css.iconFeaturesHeading)}>Who might have the data?</h2>
						<ul>
							<li>Human Resources or Clinic Manager </li>
							<li>IT or Decision support</li>
							<li>Revenue Cycle</li>
							<li>Managed Care/ Contracting</li>
							<li>Finance / Reimbursement</li>
						</ul>
					</div>
				</Container>
			</section>
			<Section>
				<Container>
					<div className={css.callToActionPanel}>
						<div className={css.iconWrapper}>
							<Icon className={css.icon} type='Send' color='white' size='large'></Icon>
						</div>
						<div className={css.image}></div>
						<div className={css.right}>
							<div className={css.text}>
								<h2 className={classes('h0', css.heading)}>
									Looking to dig even deeper? We're here to help.
								</h2>
								<p>We're more than consultants; we're catalysts.</p>
								<Link type='primary' external to='mailto:info@fronthealth.com'>
									Contact us for assistance.
								</Link>
							</div>
						</div>
					</div>
				</Container>
			</Section>
		</PageFrame>
	);
};
