import React, { useRef, useEffect, useState } from 'react';
import css from './Dropdown.module.scss';
import classes from 'classnames';
import { Button } from 'components/Button/Button';
import { IButtonBaseProps } from 'common/interfaces/IButtonBaseProps';
import { removePropertiesFromObjects } from 'common/utils/removePropertiesFromObjects';
import { useToggle } from 'common/hooks/useToggle';

export type IDropdownProps = IButtonBaseProps & {
	className?: string;
	buttonClassName?: string;
	DropdownContent: React.ComponentType<any>;
	dropdownPosition?: 'left' | 'right';
	isOpen?: boolean;
	classNames?: {
		dropdownContentArea?: string;
	};
};

export const Dropdown: React.FC<IDropdownProps> = props => {
	const {
		children,
		className,
		buttonClassName,
		DropdownContent,
		dropdownPosition = 'left',
		isOpen = false,
		classNames
	} = props;

	const [keyBoardEventState] = useState({
		eventAdded: false,
		event: null
	});

	const toggle = useToggle(null);

	const dropdownRef = useRef(null);
	const buttonRef = useRef(null);

	useEffect(() => {
		toggle.setRef(dropdownRef);
		if (isOpen !== null) {
			toggle.setIsShown(isOpen);
		}
		// eslint-disable-next-line
	}, [dropdownRef, isOpen]);

	useEffect(() => {
		if (toggle.isShown) {
			if (!keyBoardEventState.eventAdded) {
				keyBoardEventState.event = () => {
					if (
						dropdownRef.current &&
						!dropdownRef.current.contains(document.activeElement) &&
						buttonRef.current !== document.activeElement
					) {
						toggle.setSourceEventTarget(null);
						toggle.setIsShown(false);
						document.removeEventListener('keyup', keyBoardEventState.event);
						keyBoardEventState.event = null;
					}
				};
				document.addEventListener('keyup', keyBoardEventState.event);
				keyBoardEventState.eventAdded = true;
			}
		} else {
			document.removeEventListener('keyup', keyBoardEventState.event);
			keyBoardEventState.eventAdded = false;
			keyBoardEventState.event = null;
		}
		// eslint-disable-next-line
	}, [toggle.isShown]);

	// Remove these props from the inherited button base props
	const dropdownPropsToRemove = [
		'className',
		'onClick',
		'DropdownContent',
		'isOpen',
		'dropdownPosition'
	];
	// Remove the above props and create a variable to add to the button component
	const buttonProps = removePropertiesFromObjects(dropdownPropsToRemove, props);

	return (
		<div
			className={classes(css.dropdownWrapper, className, {
				[css.positionRight]: dropdownPosition === 'right'
			})}
		>
			<Button
				onClick={event => toggle.toggle(event)}
				className={classes(css.dropdownButton, buttonClassName)}
				{...buttonProps}
				aria-expanded={toggle.isShown}
				ref={buttonRef}
			>
				{children}
			</Button>

			{toggle.isShown && (
				<div
					className={classes(css.dropdownContentArea, classNames?.dropdownContentArea)}
					ref={dropdownRef}
				>
					<DropdownContent toggle={toggle}></DropdownContent>
				</div>
			)}
		</div>
	);
};
