import React from 'react';
import css from './ProgressHeader.module.scss';
import classes from 'classnames';
import { Container } from 'components/Container/Container';
import { Link, NavLink } from 'react-router-dom';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { useLocation, useParams } from 'react-router';
import { ReactComponent as Arrow } from 'assets/svg/arrow.svg';

interface IProgressHeader {
	className?: string;
	subtitle?: string;
	title?: string;
}

export const ProgressHeader: React.FC<IProgressHeader> = props => {
	// @ts-ignore
	const { assessmentId } = useParams();
	const location = useLocation();
	const { className } = props;
	const firestore = firebase.firestore();

	const assessmentDocument = firestore.collection('assessments').doc(assessmentId);
	const [assessment] = useDocumentData(assessmentDocument);

	const costsDocument = firestore.collection('costs').doc(assessmentId);
	const [costs] = useDocumentData(costsDocument);

	let page = 'summary';
	if (location.pathname.indexOf(assessmentId + '/revenue') > -1) {
		page = 'revenue';
	} else if (location.pathname.indexOf(assessmentId + '/costs') > -1) {
		page = 'costs';
	}
	const navItems =
		page === 'revenue'
			? [
					{
						title: 'Contract Types',
						to: '/assessment/' + assessmentId + '/revenue'
					},
					{
						title: 'Payers',
						to: '/assessment/' + assessmentId + '/revenue/payers'
					},
					{
						title: 'Utilization',
						to: '/assessment/' + assessmentId + '/revenue/utilization'
					},
					{
						title: 'Summary',
						to: '/assessment/' + assessmentId + '/revenue/summary'
					}
			  ]
			: [
					{
						title: 'Choose your assessment options',
						to: '/assessment/' + assessmentId + '/costs'
					},
					{
						title: 'Select your team roles',
						to: '/assessment/' + assessmentId + '/costs/roles'
					},
					{
						title: 'Current team costs',
						to: '/assessment/' + assessmentId + '/costs/current-team',
						hidden: costs?.type === 'future'
					},
					{
						title: 'Future team costs',
						to: '/assessment/' + assessmentId + '/costs/future-team',
						hidden: costs?.type === 'current'
					},
					{
						title: 'Additional future costs',

						to: '/assessment/' + assessmentId + '/costs/future-other',
						hidden: costs?.type === 'current'
					},
					{
						title: 'Summary',
						to: '/assessment/' + assessmentId + '/costs/summary'
					}
			  ];
	let activeFound = false;
	let activeIndex = 0;
	if (page === 'revenue') {
		activeIndex = 3;
	}
	navItems.forEach((item: any, index: number) => {
		if (activeFound === false) {
			item.active = true;
			if (item.to === location.pathname) {
				activeFound = true;
				activeIndex = index;
			}
		}
	});

	return (
		<div className={classes(className, css.progressHeader)}>
			<Container className={css.container}>
				<div>
					<nav className={classes('h6', css.subtitle)}>
						<Link to={'/assessment/' + assessmentId} className='inheritLink'>
							{assessment?.name}
						</Link>
					</nav>
					<h1 className={css.heading}>
						{page === 'costs'
							? 'Costs Analysis'
							: page === 'revenue'
							? 'Revenue Analysis'
							: 'Comprehensive Summary'}
					</h1>
					{page !== 'summary' && (
						<div>
							Click the bars below to jump from step to step as desired{' '}
							<Arrow className={css.arrow}></Arrow>
						</div>
					)}
				</div>
				{page !== 'summary' && (
					<h2 className={classes(css.currentStep, 'h2')}>
						Step {activeIndex + 1} of {navItems.length}
					</h2>
				)}
			</Container>
			{page !== 'summary' && (
				<div className={css.progressNav}>
					{navItems.map((item: any, index: any) => (
						<React.Fragment key={'progressHeader' + index}>
							{!item.hidden && (
								<>
									<NavLink
										title={item.title}
										to={item.to}
										className={item.active && css.active}
									></NavLink>
								</>
							)}
						</React.Fragment>
					))}
				</div>
			)}
		</div>
	);
};
