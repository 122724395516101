import React, { useEffect, useState } from 'react';
import { useMessage } from '../Message/MessageProvider';
import { CSSTransition } from 'react-transition-group';
import { Alert } from 'components/Alert/Alert';
import css from './Message.module.scss';
import classes from 'classnames';

import { TAlertTypes } from 'common/types/TAlertTypes';

export interface IMessageProps {
	id?: number;
	className?: string;
	type?: TAlertTypes;
	content: string;
	timeout?: number; // milliseconds
}

const Message: React.FC<IMessageProps> = props => {
	const { id, type, className, content, timeout = 4000 } = props;
	const { removeMessage } = useMessage();
	const [isShown, setIsShown] = useState(false);

	useEffect(() => {
		setIsShown(true);
		setTimeout(() => {
			setIsShown(false);
		}, timeout);
	}, [timeout]);

	return (
		<CSSTransition
			in={isShown}
			timeout={400}
			classNames='scale-fade'
			unmountOnExit
			onExited={() => removeMessage(id)}
		>
			<Alert
				type={type}
				setIsShown={setIsShown}
				isDismissible={true}
				className={classes(className, css.message)}
				accessibilityRole='status'
			>
				{content}
			</Alert>
		</CSSTransition>
	);
};

export default Message;
