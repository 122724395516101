import { Button } from 'components/Button/Button';
import { PageFrame } from 'components/PageFrame/PageFrame';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { Field, FieldArray, Form, Formik, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import css from './RevenueView.module.scss';
import { useHistory, useParams } from 'react-router';
import { ButtonPagination } from 'components/ButtonPagination/ButtonPagination';
import { ProgressHeader } from 'components/ProgressHeader/ProgressHeader';
import classes from 'classnames';
import { Section } from 'components/Section/Section';
import { Icon } from 'components/Icon/Icon';
import { Spinner } from 'components/Spinner/Spinner';
import { formatAsUSD } from 'common/utils/formatAsUSD';
import { ReactComponent as Arrow } from 'assets/svg/arrow.svg';
import { getUniqueId } from 'common/utils/getUniqueId';
import { focusOnId } from 'common/utils/focusOnId';
import { removeFrontendIdFromData } from 'common/utils/removeFrontendIdFromData';

interface IValues {
	payers: {
		contractType: string;
		name: string;
		memberLives: number;
		netFFSRevenueInpatient: number;
		netFFSRevenueOutpatient: number;
		netFFSRevenueProfessional: number;
		valueBasedNetRevenue: number;
		utilizations: any[];
	}[];
}

export const RevenueStepUtilizationView = () => {
	// @ts-ignore
	const { assessmentId } = useParams();
	const firestore = firebase.firestore();

	const revenueDocument = firestore.collection('revenue').doc(assessmentId);
	const [revenue, loading] = useDocumentData(revenueDocument);

	const history = useHistory();
	const [payerIndex, setPayerIndex] = useState(0);

	if (loading) {
		return <Spinner size='small' type='dark'></Spinner>;
	}

	const payers = revenue?.payers;

	payers?.forEach((payer: any, index: any) => {
		if (!payer.utilizations) {
			payers[index].utilizations = [
				{ name: 'Inpatient admissions' },
				{ name: 'Inpatient readmissions' },
				{ name: 'Emergency department visits' },
				{ name: 'MRI scanning' },
				{ name: 'CT scanning' },
				{ name: 'CV echo' },
				{ name: 'CV nuclear imaging' },
				{ name: 'Urgent care visit' },
				{ name: 'Orthopedics' },
				{ name: 'OB - C-section' },
				{ name: '' }
			];
		}
	});

	const moveBack = () => {
		if (payerIndex - 1 < 0) {
			history.push('/assessment/' + assessmentId + '/revenue/payers');
		} else {
			setPayerIndex(payerIndex - 1);
		}
	};
	const updateRevenue = async (data: any) => {
		try {
			await firestore
				.collection('revenue')
				.doc(assessmentId)
				.set(
					{
						...data,
						updatedAt: firebase.firestore.FieldValue.serverTimestamp()
					},
					{ merge: true }
				)
				.then(() => {
					if (payerIndex + 1 >= revenue.payers.length) {
						history.push('/assessment/' + assessmentId + '/revenue/summary');
					} else {
						window.scrollTo(0, 0);
						setPayerIndex(payerIndex + 1);
					}
				});
		} catch (error) {
			throw new Error(error);
		}
	};
	return (
		<>
			<PageFrame
				title='Revenue Analysis'
				ContentTop={() => <ProgressHeader></ProgressHeader>}
				sidebarContent={
					<>
						<strong>Revenue</strong>
						<p>
							Accurately quantifying current high revenue/high volume services by payer is a critical
							prerequisite when engaging in new at-risk arrangements. This section will allow you to more
							confidently estimate the utilization impact your population management interventions will
							have in these value contracts, allowing your team to proactively negotiate the most
							favorable terms to mitigate potential loses.
						</p>
					</>
				}
			>
				{!loading && (
					<>
						{!payers ? (
							<h2>You must complete the previous steps first.</h2>
						) : (
							<Formik
								initialValues={{
									payers: revenue.payers
								}}
								validateOnChange={false}
								validateOnBlur={false}
								onSubmit={async data => {
									await updateRevenue(removeFrontendIdFromData(data, 'payers'));
								}}
							>
								{props => {
									return (
										<>
											<h2>{revenue.payers[payerIndex].contractType} Payer Utilizations</h2>
											<h1 className={css.headingNoMargin}>
												Great, now let’s break down {revenue.payers[payerIndex].name} and their
												utilizations:
											</h1>

											<br />
											<UtilizationForm payerIndex={payerIndex} moveBack={moveBack}></UtilizationForm>
										</>
									);
								}}
							</Formik>
						)}
					</>
				)}
			</PageFrame>
		</>
	);
};

const UtilizationForm = (props: any) => {
	const { isSubmitting } = props;
	const { values, setFieldValue } = useFormikContext<IValues>();
	const resetFormValues = () => {
		setFieldValue(`payers.${props.payerIndex}.memberLives`, '');
		setFieldValue(`payers.${props.payerIndex}.netFFSRevenueInpatient`, '');
		setFieldValue(`payers.${props.payerIndex}.netFFSRevenueOutpatient`, '');
		setFieldValue(`payers.${props.payerIndex}.netFFSRevenueProfessional`, '');
		setFieldValue(`payers.${props.payerIndex}.valueBasedNetRevenue`, '');
		setFieldValue(`payers.${props.payerIndex}.utilizations`, [
			{
				name: 'Inpatient admissions',
				currentUtilizationsAmount: '',
				revenue: '',
				futureUtilizationPer1000: ''
			},
			{
				name: 'Inpatient readmissions',
				currentUtilizationsAmount: '',
				revenue: '',
				futureUtilizationPer1000: ''
			},
			{
				name: 'Emergency department visits',
				currentUtilizationsAmount: '',
				revenue: '',
				futureUtilizationPer1000: ''
			},
			{
				name: 'MRI scanning',
				currentUtilizationsAmount: '',
				revenue: '',
				futureUtilizationPer1000: ''
			},
			{
				name: 'CT scanning',
				currentUtilizationsAmount: '',
				revenue: '',
				futureUtilizationPer1000: ''
			},
			{
				name: 'CV echo',
				currentUtilizationsAmount: '',
				revenue: '',
				futureUtilizationPer1000: ''
			},
			{
				name: 'CV nuclear imaging',
				currentUtilizationsAmount: '',
				revenue: '',
				futureUtilizationPer1000: ''
			},
			{
				name: 'Urgent care visit',
				currentUtilizationsAmount: '',
				revenue: '',
				futureUtilizationPer1000: ''
			},
			{
				name: 'Orthopedics',
				currentUtilizationsAmount: '',
				revenue: '',
				futureUtilizationPer1000: ''
			},
			{
				name: 'OB - C-section',
				currentUtilizationsAmount: '',
				revenue: '',
				futureUtilizationPer1000: ''
			},
			{
				name: '',
				currentUtilizationsAmount: '',
				revenue: '',
				futureUtilizationPer1000: ''
			}
		]);
	};
	return (
		<Form>
			<Section className={css.payerSection}>
				<div className={css.formTitleAndReset}>
					<h3 className={classes(css.uppercase, 'h5')}>
						{values.payers[props.payerIndex].name} Details
					</h3>
					<Button
						type='link'
						className={css.resetPayerButton}
						onClick={event => {
							event.preventDefault();
							resetFormValues();
						}}
						htmlType='button'
					>
						Clear All Values
					</Button>
				</div>
				<div className='tableWrapper'>
					<table cellPadding={0} cellSpacing={0} className={css.costItems}>
						<tbody>
							{values.payers.length > 0 && (
								<>
									<tr className={css.costItemRow}>
										<th>
											<div className={css.thInner}>
												<div className={css.text}>Member Lives</div>
												<Icon
													className={css.icon}
													type={'Info'}
													size='small'
													color='blueLight'
													tooltip='The number of attributed or assigned members in the contract.
                                            '
												></Icon>
											</div>
										</th>
										<th>
											<div className={css.thInner}>
												<div className={css.text}>Net FFS Revenue - Inpatient</div>
												<Icon
													className={css.icon}
													type={'Info'}
													size='small'
													color='blueLight'
													tooltip='Net Fee-For-Service (FFS) revenue - Inpatient is the annual amount paid for inpatient services for the contract.
                                            '
												></Icon>
											</div>
										</th>
										<th>
											<div className={css.thInner}>
												<div className={css.text}>Net FFS Revenue - Outpatient</div>
												<Icon
													className={css.icon}
													type={'Info'}
													size='small'
													color='blueLight'
													tooltip='Net Fee-For-Service (FFS) revenue - Outpatient is the annual amount paid for outpatient services for the contract.
                                            '
												></Icon>
											</div>
										</th>
										<th>
											<div className={css.thInner}>
												<div className={css.text}>Net FFS Revenue - Professional</div>
												<Icon
													className={css.icon}
													type={'Info'}
													size='small'
													color='blueLight'
													tooltip='Net Fee-For-Service (FFS) revenue - Professional is the annual amount paid for professional services for the contract.
                                            '
												></Icon>
											</div>
										</th>
										<th>
											<div className={css.thInner}>
												<div className={css.text}>Total Net FFS Revenue</div>
												<Icon
													className={css.icon}
													type={'Info'}
													size='small'
													color='blueLight'
													tooltip='Total Net Fee-For-Service (FFS) = Inpatient + Outpatient + Professional
                                            '
												></Icon>
											</div>
										</th>
										<th>
											<div className={css.thInner}>
												<div className={css.text}>Value-Based Net Revenue</div>
												<Icon
													className={css.icon}
													type={'Info'}
													size='small'
													color='blueLight'
													tooltip='Revenue from value-based contract performance not included in previous revenue columns.
                                            '
												></Icon>
											</div>
										</th>
									</tr>
									<tr className={css.costItemRow}>
										<td className={css.small}>
											<Field
												name={`payers.${props.payerIndex}.memberLives`}
												placeholder={'Enter member lives (#)'}
												type='number'
											></Field>
										</td>
										<td className={css.small}>
											<Field
												name={`payers.${props.payerIndex}.netFFSRevenueInpatient`}
												placeholder={'Enter revenue ($)'}
												type='number'
											></Field>
										</td>
										<td className={css.small}>
											<Field
												name={`payers.${props.payerIndex}.netFFSRevenueOutpatient`}
												placeholder={'Enter revenue ($)'}
												type='number'
											></Field>
										</td>
										<td className={css.small}>
											<Field
												name={`payers.${props.payerIndex}.netFFSRevenueProfessional`}
												placeholder={'Enter revenue ($)'}
												type='number'
											></Field>
										</td>
										{/* @ts-ignore */}
										<td className={css.small}>
											{formatAsUSD(
												(values.payers[props.payerIndex].netFFSRevenueInpatient || 0) +
													(values.payers[props.payerIndex].netFFSRevenueOutpatient || 0) +
													(values.payers[props.payerIndex].netFFSRevenueProfessional || 0)
											)}
										</td>
										<td className={css.small}>
											<Field
												name={`payers.${props.payerIndex}.valueBasedNetRevenue`}
												placeholder={'Enter value ($)'}
												type='number'
											></Field>
										</td>
									</tr>
								</>
							)}
						</tbody>
					</table>
				</div>
				<div className={css.spreadsheetLink}>
					<div className={css.text}>
						Not sure how to calculate your value-based net revenue?.{' '}
						<a href='/assets/shared-savings-worksheet.xlsm'>
							Download our spreadsheet to help you calculate and populate it here
						</a>
						.
					</div>
					<Arrow className={css.arrow}></Arrow>
				</div>
			</Section>
			<Section className={css.utilizationSection}>
				<h3 className={classes(css.uppercase, 'h5')}>
					{values.payers[props.payerIndex].name} Utilizations
				</h3>

				<FieldArray name={`payers.${props.payerIndex}.utilizations`}>
					{({ remove, push }) => (
						<>
							<div className='tableWrapper'>
								<table cellPadding={0} cellSpacing={0} className={css.costItems}>
									<tbody>
										{values.payers[props.payerIndex].utilizations.length > 0 && (
											<>
												<tr className={css.costItemRow}>
													<th className={css.largest}>
														<div className={css.thInner}>
															<div className={css.text}>Services</div>
															<Icon
																className={css.icon}
																type={'Info'}
																size='small'
																color='blueLight'
																tooltip='Select the services that are typically the highest cost or most utilized for the payer.'
															></Icon>
														</div>
													</th>

													<th className={css.large}>
														<div className={css.thInner}>
															<div className={css.text}>Total number of current utilizations</div>
															<Icon
																className={css.icon}
																type={'Info'}
																size='small'
																color='blueLight'
																tooltip='The number of annual admissions, visits, procedures or services for the payer.'
															></Icon>
														</div>
													</th>
													<th>
														<div className={css.thInner}>
															<div className={css.text}>Revenue</div>
															<Icon
																className={css.icon}
																type={'Info'}
																size='small'
																color='blueLight'
																tooltip='Revenue is the total annual amount paid for the event type for the payer. '
															></Icon>
														</div>
													</th>
													<th>
														<div className={css.thInner}>
															<div className={css.text}>Current utilization per 1,000</div>
															<Icon
																className={css.icon}
																type={'Info'}
																size='small'
																color='blueLight'
																tooltip='Current Utilization per 1,000 standardizes the services for comparison and management. Current Utilizations per 1,000 = Utilizations x Members ÷ 1,000.'
															></Icon>
														</div>
													</th>
													<th>
														<div className={css.thInner}>
															<div className={css.text}>Revenue per Utilization</div>
															<Icon
																className={css.icon}
																type={'Info'}
																size='small'
																color='blueLight'
																tooltip='Revenue per Utilization = Revenue ÷ Total Utilizations.'
															></Icon>
														</div>
													</th>
													<th>
														<div className={css.thInner}>
															<div className={css.text}>Future utilization per 1,000</div>
															<Icon
																className={css.icon}
																type={'Info'}
																size='small'
																color='blueLight'
																tooltip='Are there any contract targets to reduce high cost utilizations? Please enter the target utilization rate here.'
															></Icon>
														</div>
													</th>
													<th>
														<div className={css.thInner}>
															<div className={css.text}>Change in revenue</div>
															<Icon
																className={css.icon}
																type={'Info'}
																size='small'
																color='blueLight'
																tooltip='This is the change in fee-for-service revenue anticipated due to the potential decrease in high-cost utilizations.'
															></Icon>
														</div>
													</th>
													<th className={css.medium}>
														<></>
													</th>
												</tr>
												{values.payers[props.payerIndex].utilizations.map((item, index) => (
													<tr className={css.costItemRow} key={index}>
														<td className={css.largest}>
															<Field
																name={`payers.${props.payerIndex}.utilizations.${index}.name`}
																placeholder={'Enter utilization'}
																type='text'
																id={item.uniqueId}
															></Field>
														</td>
														<td className={css.small}>
															<Field
																name={`payers.${props.payerIndex}.utilizations.${index}.currentUtilizationsAmount`}
																placeholder={'Enter total (#)'}
																type='number'
															></Field>
														</td>
														<td className={css.small}>
															<Field
																name={`payers.${props.payerIndex}.utilizations.${index}.revenue`}
																placeholder={'Enter revenue ($)'}
																type='number'
															></Field>
														</td>
														<td className={css.smallest}>
															{/* current utilization per 1000 */}
															{(
																(values.payers[props.payerIndex].utilizations[index]
																	.currentUtilizationsAmount /
																	values.payers[props.payerIndex].memberLives) *
																	1000 || 0
															).toFixed(0)}
														</td>
														<td className={css.smallest}>
															{/* revenue per utilization */}
															{formatAsUSD(
																values.payers[props.payerIndex].utilizations[index].revenue /
																	values.payers[props.payerIndex].utilizations[index]
																		.currentUtilizationsAmount || 0
															)}
														</td>
														<td>
															<Field
																name={`payers.${props.payerIndex}.utilizations.${index}.futureUtilizationPer1000`}
																placeholder={'Enter value (#)'}
																step='1'
																type='number'
															></Field>
														</td>
														<td>
															{/* change in revenue */}
															{formatAsUSD(
																(Math.round(values.payers[props.payerIndex].utilizations[index].futureUtilizationPer1000 / 1000 
																* values.payers[props.payerIndex].memberLives) - 
																Math.round(Math.round(values.payers[props.payerIndex].utilizations[index].currentUtilizationsAmount
																/ values.payers[props.payerIndex].memberLives
																* 1000)/ 1000 * values.payers[props.payerIndex].memberLives)
																) *
																(values.payers[props.payerIndex].utilizations[index].revenue /
																 	values.payers[props.payerIndex].utilizations[index]
																 		.currentUtilizationsAmount)
																
															)}
														</td>

														<td className={classes(css.buttonWrap, css.smallest)}>
															<Button
																type='control'
																size='small'
																aria-label='Remove row'
																iconRight={{ type: 'Cancel', color: 'blueLight', size: 'large' }}
																onClick={event => {
																	event.preventDefault();
																	remove(index);
																}}
																htmlType='button'
															></Button>
														</td>
													</tr>
												))}
											</>
										)}
									</tbody>
								</table>
							</div>

							<Button
								type='link'
								iconLeft={{ type: 'Plus', color: 'green' }}
								onClick={event => {
									const uniqueId = getUniqueId();
									event.preventDefault();
									push({ uniqueId, name: '' });
									focusOnId(uniqueId);
								}}
								htmlType='button'
							>
								Add Service Type
							</Button>
						</>
					)}
				</FieldArray>
			</Section>
			<ButtonPagination>
				<Button
					type='secondary'
					onClick={() => {
						props.moveBack();
					}}
					htmlType='button'
				>
					Back
				</Button>
				<Button type='primary' loadingLabel='Saving' htmlType='submit' isLoading={isSubmitting}>
					Next
				</Button>
			</ButtonPagination>
		</Form>
	);
};
