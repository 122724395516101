import { Button } from 'components/Button/Button';
import { PageFrame } from 'components/PageFrame/PageFrame';
import { Section } from 'components/Section/Section';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { Field, FieldArray, Form, Formik, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import css from './CostsView.module.scss';
import { useHistory, useParams } from 'react-router';
import { ButtonPagination } from 'components/ButtonPagination/ButtonPagination';
import { Link } from 'components/Link/Link';
import classes from 'classnames';
import { ProgressHeader } from 'components/ProgressHeader/ProgressHeader';
import { Icon } from 'components/Icon/Icon';
import { getUniqueId } from 'common/utils/getUniqueId';
import { focusOnId } from 'common/utils/focusOnId';
import { removeFrontendIdFromData } from 'common/utils/removeFrontendIdFromData';

export const CostsStepFutureTeamView = () => {
	// @ts-ignore
	const { assessmentId } = useParams();
	const firestore = firebase.firestore();

	const costsDocument = firestore.collection('costs').doc(assessmentId);
	const [costs, loading] = useDocumentData(costsDocument);
	const history = useHistory();

	const updateCosts = async (data: any) => {
		try {
			await firestore
				.collection('costs')
				.doc(assessmentId)
				.set(
					{
						...data,
						updatedAt: firebase.firestore.FieldValue.serverTimestamp()
					},
					{ merge: true }
				)
				.then(() => {
					history.push('/assessment/' + assessmentId + '/costs/future-other');
				});
		} catch (error) {
			throw new Error(error);
		}
	};
	const [isSubmitting, setIsSubmitting] = useState(false);
	let futureItemsInitialValues: any = [];
	if (costs) {
		futureItemsInitialValues = costs.futureItems
			? costs.futureItems
			: costs.futureRoles
			? costs.futureRoles.map((item: string) => ({
					role: item,
					// @ts-ignore
					quantity: '',
					// @ts-ignore
					panelPerFTE: '',
					// @ts-ignore
					salaries: '',
					// @ts-ignore
					additionalBenefitCost: '',
					// @ts-ignore
					benefits: '',
					isPreSelected: item === 'Other' ? false : true
			  }))
			: [
					{
						role: '',
						quantity: '',
						panelPerFTE: '',
						salaries: '',
						additionalBenefitCost: '',
						benefits: ''
					}
			  ];
		if (costs.items) {
			costs.items.forEach((item: any) => {
				futureItemsInitialValues.forEach((futureItem: any, index: number) => {
					if (
						futureItem.role === item.role &&
						futureItem.salaries === '' &&
						futureItem.quantity === ''
					) {
						futureItemsInitialValues[index].uniqueId = item.uniqueId;
						futureItemsInitialValues[index].quantity = item.quantity;
						futureItemsInitialValues[index].salaries = item.salaries;
						futureItemsInitialValues[index].benefits = item.benefits;
						futureItemsInitialValues[index].additionalBenefitCost = item.additionalBenefitCost;
					}
				});
			});
		}
	}

	return (
		<PageFrame
			title='Cost Analysis'
			ContentTop={() => <ProgressHeader></ProgressHeader>}
			sidebarContent={
				<>
					<strong>Future Costs</strong>
					<p>
						Use this section to create high performing primary care teams, which are at the core of
						succeeding in risk contracts. Future team staffing models can be assessed across multiple
						scenarios to find one that is efficient and effective in achieving value.
					</p>
				</>
			}
		>
			{!loading && (
				<>
					<p className='h2'>Future Costs</p>
					<h2 className='h1'>
						Tell us about your <span className='underline'>future</span> primary care team by role:
					</h2>
					<p>The system has pulled in your current numbers to make adjustments easier for you. </p>
					<br />
					<Formik
						initialValues={{
							assumedBenefitsPercentage: costs?.assumedBenefitsPercentage || 31,
							futureItems: futureItemsInitialValues,
							totalFutureTeamCosts: null
						}}
						validateOnChange={false}
						validateOnBlur={true}
						onSubmit={async (data: IValues) => {
							setIsSubmitting(true);
							await updateCosts(removeFrontendIdFromData(data, 'futureItems'));
						}}
					>
						{props => (
							<CostsForm
								costs={costs}
								isSubmitting={isSubmitting}
								assessmentId={assessmentId}
								{...props}
							></CostsForm>
						)}
					</Formik>
				</>
			)}
		</PageFrame>
	);
};

interface IValues {
	assumedBenefitsPercentage: number;
	futureItems: {
		uniqueId?: string;
		role: string;
		quantity: number;
		panelPerFTE: number;
		salaries: number;
		additionalBenefitCost: number;
		benefits: number;
		isPreSelected?: boolean;
	}[];
	totalFutureTeamCosts: number;
}

const CostsForm = (props: any) => {
	const { isSubmitting } = props;
	const { values, setFieldValue } = useFormikContext<IValues>();
	useEffect(() => {
		let tempTotalFutureTeamCosts = 0;
		values.futureItems.forEach((item, index) => {
			const tempBenefits =
				item.salaries * values.assumedBenefitsPercentage * 0.01 + (item.additionalBenefitCost || 0) || 0;
			if (tempBenefits !== item.benefits) {
				setFieldValue(`futureItems.${index}.benefits`, tempBenefits.toFixed(2));
			}
			if (item.salaries && item.quantity && tempBenefits) {
				tempTotalFutureTeamCosts += (item.salaries + tempBenefits) * item.quantity;
			}
		});
		if (tempTotalFutureTeamCosts !== values.totalFutureTeamCosts) {
			setFieldValue('totalFutureTeamCosts', tempTotalFutureTeamCosts);
		}

		// eslint-disable-next-line
	}, [values]);

	return (
		<Form>
			{' '}
			<Section>
				<h1 className={'h4'}>Future Costs</h1>
				<FieldArray name='futureItems'>
					{({ remove, push }) => (
						<>
							<div className='tableWrapper'>
								<table cellPadding={0} cellSpacing={0} className={css.costItems}>
									<tbody>
										{values.futureItems.length > 0 && (
											<>
												<tr className={css.costItemRow}>
													<th className={css.medium}>Role</th>
													<th>
														<div className={css.thInner}>
															<div className={css.text}>Quantity</div>
															<Icon
																className={css.icon}
																type={'Info'}
																size='small'
																color='blueLight'
																tooltip='Please enter the number of Full Time Equivalent (FTE) team members for each role.'
															></Icon>
														</div>
													</th>
													<th className={css.large}>
														<div className={css.thInner}>
															<div className={css.text}>Panel per full-time equivalent (FTE)</div>
															<Icon
																className={css.icon}
																type={'Info'}
																size='small'
																color='blueLight'
																tooltip='Please enter the 12 or 18 month active panel for primary roles that are assigned their own patients.As an example, a typical active panel for traditional fee-for service primary care setting ranges between 1,800 and 2,400 patients; whereas value-based settings can manage larger patient panels.'
															></Icon>
														</div>
													</th>
													<th>
														<div className={css.thInner}>
															<div className={css.text}>Median Salary</div>
															<Icon
																className={css.icon}
																type={'Info'}
																size='small'
																color='blueLight'
																tooltip='Please enter the average base salary from current clinic records for each role.If there are significant differences in salaries within a role click “Add Role” to contrast variations.'
															></Icon>
														</div>
													</th>
													<th>
														<div className={css.thInner}>
															<div className={css.text}>Additional Benefit Cost (optional)</div>
															<Icon
																className={css.icon}
																type={'Info'}
																size='small'
																color='blueLight'
																tooltip='Please enter the cost of additional benefits other than base salary, which might be around 30% of salary.'
															></Icon>
														</div>
													</th>
													<th className={css.small}></th>
												</tr>
												{values.futureItems.map((item, index) => (
													<tr className={css.costItemRow} key={index}>
														<td className={css.medium}>
															{item.isPreSelected ? (
																<strong>{item.role}</strong>
															) : (
																<Field
																	name={`futureItems.${index}.role`}
																	placeholder={'Enter role'}
																	type='text'
																	id={item.uniqueId}
																></Field>
															)}
														</td>
														<td className={css.small}>
															<Field
																name={`futureItems.${index}.quantity`}
																placeholder={'Enter quantity (#)'}
																type='number'
															></Field>
														</td>
														<td className={css.large}>
															<Field
																name={`futureItems.${index}.panelPerFTE`}
																type='number'
																placeholder='Enter panel per FTE (#)'
																value={
																	item.role !== 'MD/DO' && item.role !== 'PA/NP' ? '' : item.panelPerFTE
																}
																disabled={item.role !== 'MD/DO' && item.role !== 'PA/NP'}
															></Field>
														</td>
														<td>
															<Field
																name={`futureItems.${index}.salaries`}
																placeholder={'Enter salaries ($)'}
																step='1'
																type='number'
															></Field>
														</td>

														<td>
															<Field
																name={`futureItems.${index}.additionalBenefitCost`}
																type='number'
																placeholder='Enter cost ($)'
																step='1'
																value={item.additionalBenefitCost}
															></Field>
														</td>

														<td className={classes(css.buttonWrap, css.smallest)}>
															<Button
																type='control'
																size='small'
																aria-label='Remove row'
																iconRight={{ type: 'Cancel', color: 'blueLight', size: 'large' }}
																htmlType='button'
																onClick={event => {
																	event.preventDefault();
																	remove(index);
																}}
															></Button>
														</td>
													</tr>
												))}
											</>
										)}
									</tbody>
								</table>
							</div>

							<Button
								type='link'
								iconLeft={{ type: 'Plus', color: 'green' }}
								onClick={event => {
									const uniqueId = getUniqueId();
									event.preventDefault();
									push({ uniqueId, role: '', quantity: '', salaries: '', benefits: '' });
									focusOnId(uniqueId);
								}}
								htmlType='button'
							>
								Add Role
							</Button>
						</>
					)}
				</FieldArray>
				{/* <br /> */}
				{/* <label>Total Future Costs (So Far)</label>
					<input disabled value={formatAsUSD(values.totalFutureTeamCosts)}></input> */}

				<ButtonPagination>
					<Link
						type='secondary'
						to={
							'/assessment/' +
							props.assessmentId +
							(props.costs?.type === 'future' ? '/costs/roles' : '/costs/current-team')
						}
					>
						Back
					</Link>
					<Button type='primary' loadingLabel='Saving' htmlType='submit' isLoading={isSubmitting}>
						Next
					</Button>
				</ButtonPagination>
			</Section>
		</Form>
	);
};
