import React from 'react';
import css from './TableMetaContent.module.scss';
import classes from 'classnames';

export interface ITableMetaContentContentClassNames {
	contentAreaLeft?: string;
	contentAreaRight?: string;
}

interface ITableMetaContent {
	contentLeft?: React.ReactNode;
	contentRight?: React.ReactNode;
	className?: string;
	contentClassNames?: ITableMetaContentContentClassNames;
}

export const TableMetaContent: React.FC<ITableMetaContent> = props => {
	const { contentLeft, contentRight, className, contentClassNames } = props;

	return (
		<div className={classes(css.tableMetaContent, className)}>
			<div className={classes(css.contentArea, contentClassNames?.contentAreaLeft)}>{contentLeft}</div>
			{contentRight && (
				<div
					className={classes(
						css.contentArea,
						{ [css.contentAreaSpacing]: contentLeft && contentRight },
						contentClassNames?.contentAreaRight
					)}
				>
					{contentRight}
				</div>
			)}
		</div>
	);
};
