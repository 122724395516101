import React from 'react';
import css from './Section.module.scss';
import classes from 'classnames';
import { Icon } from 'components/Icon';

export interface ISectionProps {
	title?: string;
	className?: string;
	titleClassName?: string;
	titleTooltip?: string;
	titleInfoAlwaysShown?: boolean;
	description?: React.ReactNode | string;
}

export const Section: React.FC<ISectionProps> = props => {
	const {
		title,
		children,
		className,
		titleClassName,
		titleTooltip,
		titleInfoAlwaysShown,
		description
	} = props;
	return (
		<div className={classes(css.section, className)}>
			{title && (
				<div className={css.titleContainer}>
					<h2 className={classes(titleClassName, 'h5', css.title)}>{title}</h2>
					&nbsp;
					{titleTooltip && (
						<Icon
							tooltip={titleTooltip}
							className={css.titleIcon}
							tooltipAlwaysShown={titleInfoAlwaysShown}
							marginSize='small'
							type='Info'
							size='small'
							color='blue'
						/>
					)}
				</div>
			)}

			{description && (
				<p className={css.description}>
					{typeof description === 'string' ? description : <>{description}</>}
				</p>
			)}
			<div className={classes(css.content, { [css.hasTitleOrDescription]: title || description })}>
				{children}
			</div>
		</div>
	);
};
