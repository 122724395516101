import React from 'react';
import css from './Panel.module.scss';
import classes from 'classnames';
import { Icon } from 'components/Icon';

interface IPanelProps {
	title?: string;
	headingLevel?: 'h2' | 'h3';
	contentTopRight?: React.ReactNode;
	description?: string | React.ReactNode;
	hasPadding?: boolean;
	className?: string;
	doesPanelWrapTable?: boolean;
	hasSpaceBelowTopArea?: boolean;
	classnames?: {
		title?: string;
		content?: string;
	};
	tooltip?: string;
}

export const Panel: React.FC<IPanelProps> = props => {
	const {
		children,
		title,
		contentTopRight,
		hasPadding = true,
		className,
		description,
		doesPanelWrapTable,
		headingLevel = 'h2',
		hasSpaceBelowTopArea = true,
		classnames,
		tooltip
	} = props;
	return (
		<div
			className={classes(
				css.panel,
				className,
				{ [css.hasPadding]: hasPadding },
				{ [css.doesPanelWrapTable]: doesPanelWrapTable }
			)}
		>
			{(title || description) && (
				<div className={classes(css.topArea, { [css.hasSpaceBelowTopArea]: hasSpaceBelowTopArea })}>
					<div className={css.headingArea}>
						{headingLevel === 'h2' ? (
							<div className={css.titleWithTooltip}>
								<h2 className={classes(css.title, classnames?.title)}>{title}</h2>
								&nbsp;
								{tooltip && (
									<Icon
										tooltip={tooltip}
										className={css.toolTipIcon}
										type='Info'
										color='blue'
										size='small'
									/>
								)}
							</div>
						) : (
							<h3 className={classes(css.title, classnames?.title)}>{title}</h3>
						)}
						{contentTopRight && <div className={css.contentTopRight}>{contentTopRight}</div>}
					</div>
					{description && <p className={css.description}>{description}</p>}
				</div>
			)}
			{classnames?.content ? <div className={classnames.content}>{children}</div> : children}
		</div>
	);
};
