import React, { useState } from 'react';
import { getUniqueId } from 'common/utils/getUniqueId';
import { InputFrame, IInputFrame } from 'components/Form/InputFrame';
import { IInputCheckbox, InputCheckbox } from 'components/Form/InputCheckbox/InputCheckbox';
import { RemoveProp } from 'common/types/TypeHelpers';
import css from './CheckboxGroup.module.scss';
import classes from 'classnames';

type ICheckboxGroup = {
	items: IInputCheckbox[];
	styleType?: 'checkbox' | 'button';
} & RemoveProp<RemoveProp<IInputFrame, 'inputId'>, 'messageId'>;

export const CheckboxGroup: React.FC<ICheckboxGroup> = props => {
	const {
		label,
		items,
		className,
		inputMessage,
		errorMessage,
		formikProps,
		isLabelVisible,
		name,
		tooltip,
		styleType = 'checkbox'
	} = props;
	const [inputId] = useState(getUniqueId());
	const [messageId] = useState(getUniqueId());

	return (
		<InputFrame
			inputId={inputId}
			isLabelVisible={isLabelVisible}
			label={label}
			messageId={messageId}
			inputMessage={inputMessage}
			formikProps={formikProps}
			name={name}
			errorMessage={errorMessage}
			className={className}
			isCheckboxGroup
			tooltip={tooltip}
		>
			<div className={classes(css['type-' + styleType], css.checkboxGroup)}>
				{items.map((item, key) => (
					<InputCheckbox
						{...item}
						styleType={styleType}
						key={key}
						name={name}
						className={css.checkbox}
						formikProps={formikProps}
					></InputCheckbox>
				))}
			</div>
		</InputFrame>
	);
};
