export const abbreviateNumberValue = (value: number): string => {
	const thousand = 1000;
	const thousandAbbreviation = 'K';
	const million = 1000000;
	const millionAbbreviation = 'M';
	const billion = 1000000000;
	const billionAbbreviation = 'B';
	const trillion = 1000000000000;
	const trillionAbbreviation = 'T';

	if (value < thousand) {
		return `${value}`;
	} else if (value < million) {
		return `${(value / thousand).toFixed(1).replace('.0', '')}${thousandAbbreviation}`;
	} else if (value < billion) {
		return `${(value / million).toFixed(1).replace('.0', '')}${millionAbbreviation}`;
	} else if (value < trillion) {
		return `${(value / billion).toFixed(1).replace('.0', '')}${billionAbbreviation}`;
	} else {
		return `${(value / trillion).toFixed(1).replace('.0', '')}${trillionAbbreviation}`;
	}
};
