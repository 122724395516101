import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import * as Views from 'views';
import firebase from 'firebase/app';

import 'firebase/firestore';
import 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { LoadingDelay } from 'components/LoadingDelay/LoadingDelay';
import { Spinner } from 'components/Spinner/Spinner';

// Wrapper component for component lazy loading
const RouteSuspense: React.FC = props => (
	<Suspense
		fallback={
			<LoadingDelay>
				<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
			</LoadingDelay>
		}
	>
		{props.children}
	</Suspense>
);

// Definition for all the routes for the whole app
export const Routes = () => {
	const auth = firebase.auth();
	const [user, userLoading] = useAuthState(auth);

	return userLoading ? (
		<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
	) : (
		<Switch>
			<Route path='/signin'>
				{user ? (
					<Redirect to='/assessments'></Redirect>
				) : (
					<RouteSuspense>
						<Views.SignInView />
					</RouteSuspense>
				)}
			</Route>
			<Route exact path='/'>
				{user ? (
					<Redirect to='/assessments'></Redirect>
				) : (
					<RouteSuspense>
						<Views.HomeView />
					</RouteSuspense>
				)}
			</Route>
			{user ? (
				<Switch>
					<Route exact path='/'>
						<RouteSuspense>
							<Views.HomeView />
						</RouteSuspense>
					</Route>
					<Route exact path={['/assessments', '/assessments/:pageNumber']}>
						<RouteSuspense>
							<Views.AssessmentsView />
						</RouteSuspense>
					</Route>
					<Route exact path='/assessment/:assessmentId'>
						<RouteSuspense>
							<Views.AssessmentView />
						</RouteSuspense>
					</Route>
					<Route exact path='/assessment/:assessmentId/costs'>
						<RouteSuspense>
							<Views.CostsStep0View />
						</RouteSuspense>
					</Route>
					<Route exact path='/assessment/:assessmentId/costs/roles'>
						<RouteSuspense>
							<Views.CostsStepRolesView />
						</RouteSuspense>
					</Route>
					<Route exact path='/assessment/:assessmentId/costs/current-team'>
						<RouteSuspense>
							<Views.CostsStepCurrentTeamView />
						</RouteSuspense>
					</Route>
					<Route exact path='/assessment/:assessmentId/costs/future-team'>
						<RouteSuspense>
							<Views.CostsStepFutureTeamView />
						</RouteSuspense>
					</Route>
					<Route exact path='/assessment/:assessmentId/costs/future-other'>
						<RouteSuspense>
							<Views.CostsStepFutureOtherView />
						</RouteSuspense>
					</Route>
					<Route exact path='/assessment/:assessmentId/costs/summary'>
						<RouteSuspense>
							<Views.CostsSummaryView />
						</RouteSuspense>
					</Route>
					<Route exact path={['/assessment/:assessmentId/comprehensive-summary']}>
						<RouteSuspense>
							<Views.SummaryView />
						</RouteSuspense>
					</Route>
					<Route exact path='/assessment/:assessmentId/revenue'>
						<RouteSuspense>
							<Views.RevenueStep0View />
						</RouteSuspense>
					</Route>
					<Route exact path='/assessment/:assessmentId/revenue/payers'>
						<RouteSuspense>
							<Views.RevenueStepPayersView />
						</RouteSuspense>
					</Route>
					<Route exact path='/assessment/:assessmentId/revenue/utilization'>
						<RouteSuspense>
							<Views.RevenueStepUtilizationView />
						</RouteSuspense>
					</Route>
					<Route exact path={['/assessment/:assessmentId/revenue/summary']}>
						<RouteSuspense>
							<Views.RevenueSummaryView />
						</RouteSuspense>
					</Route>
					<Route path='/'>
						<RouteSuspense>
							<Views.PageNotFoundView />
						</RouteSuspense>
					</Route>
				</Switch>
			) : (
				<Redirect to='/signin'></Redirect>
			)}
		</Switch>
	);
};
