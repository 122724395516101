import { PageFrame } from 'components/PageFrame/PageFrame';
import React from 'react';

import firebase from 'firebase/app';
import 'firebase/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import css from './SignInView.module.scss';
import { Container } from 'components/Container/Container';

export const SignInView = () => {
	const uiConfig = {
		signInFlow: 'popup',
		signInSuccessUrl: '/',
		signInOptions: [
			firebase.auth.EmailAuthProvider.PROVIDER_ID,
			firebase.auth.GoogleAuthProvider.PROVIDER_ID
		]
	};

	return (
		<PageFrame
			classNames={{ pageFrameTop: css.signInPageFrame }}
			title='Sign In'
			fullWidth
			HTMLDescription='Sign into the Volume-to-Value Forecaster — Our predictor tool can help estimate the financial impact of the fee-for-outcome arrangements being considered, while simultaneously identifying potential losses, so as to enable earlier, more proactive mitigation.'
		>
			<div className={css.signIn}>
				<div className={css.content}>
					<Container className={css.container}>
						<div className={css.logo}>
							<div className={css.inner}>
								<div className={css.firstLine}>Volume-to-Value</div>
								<div className={css.secondLine}>Forecaster</div>
							</div>
						</div>
						<StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
					</Container>
				</div>
				<div className={css.image}>
					<div className={css.imageContent}>
						<Container className={css.container}>
							<h1 className={css.heading}>
								A more <strong>holistic view</strong> of value based care reimbursement
							</h1>
							<p>
								Our predictor tool can help estimate the financial impact of the fee-for-outcome
								arrangements being considered, while simultaneously identifying potential losses, so as
								to enable earlier, more proactive mitigation.{' '}
							</p>
						</Container>
					</div>
				</div>
			</div>
		</PageFrame>
	);
};
