import React from 'react';
import css from './InputMessage.module.scss';
import classes from 'classnames';
import { Icon } from 'components/Icon';

interface IInputMessageProps {
	isError?: boolean;
	id: string;
}

export const InputMessage: React.FC<IInputMessageProps> = props => {
	const { children, isError, id } = props;

	return (
		<div className={css.inputMessage} id={id} aria-live='assertive' aria-atomic='true'>
			{isError && <Icon className={css.icon} type='Alert' color={'red'} size={'smallest'}></Icon>}
			<p className={classes(css.text, { [css.error]: isError })}>{children}</p>
		</div>
	);
};
