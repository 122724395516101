import React from 'react';
import css from './Header.module.scss';
import classes from 'classnames';
import { Link } from 'components/Link/Link';
import firebase from 'firebase/app';
import 'firebase/auth';
import { DropdownList } from 'components/DropdownList/DropdownList';
import { useAuthState } from 'react-firebase-hooks/auth';
import { NavLink } from 'react-router-dom';
import { Button } from 'components/Button/Button';

export interface IHeaderProps {
	className?: string;
}

export const Header: React.FC<IHeaderProps> = props => {
	const { className } = props;
	const auth = firebase.auth();
	const [user] = useAuthState(auth);

	return (
		<>
			<header className={classes(className, css.header)}>
				<nav>
					<Link to={user ? '/assessments' : '/'} className={css.logo}>
						<div className={css.inner}>
							<div className={css.firstLine}>Volume-to-Value</div>
							<div className={css.secondLine}>Forecaster</div>
						</div>
					</Link>
					{!user ? (
						<Link className={classes(css.navItem, css.loginLink)} to='/login'>
							Login
						</Link>
					) : (
						<>
							{auth.currentUser && (
								<>
									<NavLink activeClassName={css.active} className={css.navItem} to='/assessments'>
										My Assessments
									</NavLink>
									<div>
										<Button
											type='link'
											className={classes(css.logoutMobileLink, css.navItem)}
											onClick={() => auth.signOut()}
										>
											Logout
										</Button>
									</div>
									<DropdownList
										className={css.account}
										type='control'
										iconLeft={{ color: 'blue', size: 'large', type: 'UserCircle' }}
										iconRight={{ className: css.dropdownCaret, color: 'green', type: 'CaretDown' }}
										items={[{ title: 'Logout', action: () => auth.signOut() }]}
									>
										{user.displayName ? user.displayName.split(' ')[0] : 'My Account'}
									</DropdownList>
								</>
							)}
						</>
					)}
				</nav>
			</header>
		</>
	);
};
