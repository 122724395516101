import React, { useEffect, useRef } from "react";
import css from "./ErrorView.module.scss";
import classes from "classnames";

interface IErrorView {
  isInAppFrame?: boolean;
}

export const ErrorView: React.FC<IErrorView> = (props) => {
  const { isInAppFrame } = props;

  const titleRef = useRef<HTMLHeadingElement>();
  useEffect(() => {
    titleRef.current.focus();
  }, []);

  return (
    <div
      className={classes(css.errorView, { [css.isInAppFrame]: isInAppFrame })}
    >
      <h1 className="h2" ref={titleRef} tabIndex={-1}>
        Error
      </h1>
      <p></p>
      <a href="/">Go Back</a>
    </div>
  );
};
