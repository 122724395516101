import React from 'react';
import { Link } from 'react-router-dom';
import classes from 'classnames';
import css from './Breadcrumbs.module.scss';
import { Icon } from 'components/Icon';

export interface IBreadcrumbsProps {
	navigationItems: INavigationItem[];
	className?: string;
}
interface INavigationItem {
	label: string;
	location?: string;
}

export const Breadcrumbs: React.FC<IBreadcrumbsProps> = props => {
	const { navigationItems, className } = props;

	return (
		<div className={classes(className, css.breadcrumbsWrapper)}>
			<div className={css.breadcrumbs} aria-label='breadcrumb'>
				{navigationItems.map((item, index) => {
					const currentPagePropery =
						navigationItems.length - 1 === index ? { 'aria-current': 'page' as any } : null;
					return (
						<React.Fragment key={index}>
							{item.location ? (
								<Link to={item.location} className={classes(css.breadcrumbLink)} {...currentPagePropery}>
									{item.label}
								</Link>
							) : (
								<span
									className={classes(css.breadcrumbLink, css.breacrumbLabel)}
									{...currentPagePropery}
								>
									{item.label}
								</span>
							)}
							{navigationItems.length !== index + 1 && (
								<Icon type='CaretRight' size='small' className={css.separator}></Icon>
							)}
						</React.Fragment>
					);
				})}
			</div>
		</div>
	);
};
