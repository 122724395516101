import React from 'react';
import css from './InputFrame.module.scss';
import classes from 'classnames';
import { InputMessage } from '../InputMessage/InputMessage';
import { FormikProps } from 'formik';
import { getProcessedFormikProps } from 'common/utils/getProcessedFromikProps';
import { Fieldset } from '../Fieldset';
import { Icon } from 'components/Icon';

export interface IInputFrame {
	inputId: string;
	isLabelVisible?: boolean;
	label: string;
	messageId: string;
	inputMessage?: string;
	formikProps?: FormikProps<any>;
	name: string;
	errorMessage?: string;
	className?: string;
	isCheckboxGroup?: boolean;
	tooltip?: string;
}

export const InputFrame: React.FC<IInputFrame> = props => {
	const {
		children,
		inputId,
		isLabelVisible = true,
		label,
		messageId,
		inputMessage,
		formikProps,
		name,
		errorMessage,
		className,
		isCheckboxGroup,
		tooltip
	} = props;

	const { isError, displayedMessage } = getProcessedFormikProps(
		formikProps,
		name,
		errorMessage,
		inputMessage
	);

	const labelClasses = classes(
		css.inputLabel,
		{ [css.labelError]: isError },
		{ screenReaderText: !isLabelVisible }
	);

	if (isCheckboxGroup) {
		return (
			<Fieldset
				className={classes(css.inputFrame, className)}
				legendContent={label}
				legendClassName={labelClasses}
				infoTooltip={tooltip}
			>
				{children}
				{displayedMessage && (
					<InputMessage isError={isError} id={messageId}>
						{displayedMessage}
					</InputMessage>
				)}
			</Fieldset>
		);
	} else {
		return (
			<div className={classes(css.inputFrame, className)}>
				<label htmlFor={inputId} className={labelClasses}>
					<div className={css.tooltipContainer}>
						<span className={css.label}>{label}</span>
						{tooltip && (
							<>
								&nbsp;
								<Icon
									className={css.tooltip}
									tooltip={tooltip}
									marginSize='small'
									type='Info'
									color='blue'
									size='smallest'
								/>
							</>
						)}
					</div>
				</label>
				{children}
				{displayedMessage && (
					<InputMessage isError={isError} id={messageId}>
						{displayedMessage}
					</InputMessage>
				)}
			</div>
		);
	}
};
