import React from 'react';
import css from './Pagination.module.scss';
import classes from 'classnames';
import { Link } from 'react-router-dom';
import { Icon } from 'components/Icon/Icon';
import { Copy } from 'config/Copy';

interface IPaginationProps {
	className?: string;
	itemAmount: number;
	itemsPerPage?: number;
	currentPage?: any;
	linkQueryString?: string;
	baseRoute: string;
}

interface IPageItem {
	number?: number;
	label?: string;
	accessbiilityLabel?: string;
	icon?: JSX.Element;
	current?: boolean;
	disabled?: boolean;
	className?: string;
	shown?: boolean;
}

export const Pagination: React.FC<IPaginationProps> = props => {
	const {
		className,
		itemAmount,
		itemsPerPage = 10,
		currentPage = 1,
		linkQueryString = '',
		baseRoute
	} = props;

	/**
	 * totalPaginationItems is how many spaces the pagination takes up from the first number until the last number (truncated indicators are included in this count).
	 * Must be odd to account for the center being selected when the pagination is truncated.
	 */
	const totalPaginationItems = 9;

	//innerPagesDisplayed is the totalPaginationItems minus the first and last page item
	const innerPagesDisplayed = totalPaginationItems - 2;

	const totalPages = Math.ceil(itemAmount / itemsPerPage);

	//The amount of items shown left and right of the current page indicator
	const itemsShownAdjacentToCurrentPage = Math.floor(innerPagesDisplayed / 2);

	if (currentPage > totalPages || totalPages <= 1) {
		return null;
	}

	const firstInnerItemIndex = Math.max(
		Math.min(totalPages - innerPagesDisplayed, currentPage - itemsShownAdjacentToCurrentPage),
		2
	);

	const isBeginningTruncated = firstInnerItemIndex >= itemsShownAdjacentToCurrentPage;

	const firstInnerPageNumber = isBeginningTruncated ? firstInnerItemIndex + 1 : firstInnerItemIndex;

	const lastInnerPageIndex = Math.min(
		totalPages - 1,
		Math.max(innerPagesDisplayed + 1, currentPage + itemsShownAdjacentToCurrentPage)
	);
	const isEndTruncated = lastInnerPageIndex <= totalPages - 2;

	const lastInnerPageNumber = isEndTruncated ? lastInnerPageIndex - 1 : lastInnerPageIndex;

	const pageItems: IPageItem[] = [
		{
			number: currentPage - 1,
			icon: <Icon type='CaretLeft' size='small'></Icon>,
			disabled: currentPage <= 1,
			className: css.prev,
			accessbiilityLabel: Copy.componentPaginationPreviousPageAccessibilityLabel(currentPage - 1)
		},
		{
			number: 1,
			label: '1',
			current: currentPage === 1,
			accessbiilityLabel: Copy.componentPaginationGoToPageAccessibilityLabel(1)
		},
		{
			label: '...',
			disabled: true,
			className: css.moreIndicator,
			shown: isBeginningTruncated
		}
	];

	for (let pageNumber = firstInnerPageNumber; pageNumber <= lastInnerPageNumber; pageNumber++) {
		pageItems.push({
			number: pageNumber,
			label: pageNumber.toString(),
			current: pageNumber === currentPage,
			accessbiilityLabel: Copy.componentPaginationGoToPageAccessibilityLabel(pageNumber)
		});
	}

	pageItems.push(
		{
			label: '...',
			disabled: true,
			className: css.moreIndicator,
			shown: isEndTruncated
		},
		{
			number: totalPages,
			label: totalPages.toString(),
			current: currentPage === totalPages,
			className: css.last,
			accessbiilityLabel: Copy.componentPaginationGoToPageAccessibilityLabel(totalPages)
		},
		{
			number: currentPage + 1,
			icon: <Icon type='CaretRight'></Icon>,
			disabled: currentPage === totalPages,
			className: css.next,
			accessbiilityLabel: Copy.componentPaginationNextPageAccessibilityLabel(currentPage + 1)
		}
	);

	return (
		<nav
			className={css.paginationWrapper}
			role='navigation'
			aria-label={Copy.componentPaginationAriaLabel}
		>
			<div className={classes(css.pagination, className, 'reset')}>
				{pageItems.map((pageItem: IPageItem, index: number) => {
					return (
						pageItem.shown !== false && (
							<React.Fragment key={index}>
								{pageItem.current && (
									<div className={classes(css.item, css.mobileLabel)}>
										<span aria-hidden='true' className={css.mobileLabelInner}>
											{Copy.componentPaginationPageItemScreenReader}
										</span>
									</div>
								)}
								<div
									className={classes(css.item, pageItem.className, {
										[css.current]: pageItem.current
									})}
								>
									{pageItem.disabled ? (
										<div aria-hidden={true} className={css.disabled}>
											{pageItem.icon ? pageItem.icon : pageItem.label}
										</div>
									) : (
										<Link
											aria-label={
												pageItem.current
													? Copy.componentPaginationCurrentPageIndicatorAccessibilityLabel(
															pageItem.number
													  )
													: pageItem.accessbiilityLabel
											}
											to={baseRoute + pageItem.number + linkQueryString}
											aria-current={pageItem.current}
										>
											{pageItem.icon ? pageItem.icon : pageItem.label}
										</Link>
									)}
								</div>
							</React.Fragment>
						)
					);
				})}
			</div>
		</nav>
	);
};
