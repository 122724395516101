import React from 'react';
import css from './fieldset.module.scss';
import classes from 'classnames';
import { removePropertiesFromObjects } from 'common/utils/removePropertiesFromObjects';
import { TFieldset } from 'common/types/TFieldset';
import { Icon } from 'components/Icon';

export type IFieldsetProps = {
	className?: string;
	legendContent?: string;
	legendClassName?: string;
	infoTooltip?: string;
	sizeOfLegend?: 'small' | 'large';
} & TFieldset;

export const Fieldset: React.FC<IFieldsetProps> = props => {
	const {
		legendContent,
		legendClassName,
		className,
		children,
		infoTooltip,
		sizeOfLegend = 'small'
	} = props;

	const fieldsetProps: any = removePropertiesFromObjects(
		['legendContent', 'legendClassName', 'className', 'infoTooltip', 'sizeOfLegend'],
		props
	);

	return (
		<fieldset
			className={classes(css.fieldset, className, css[`legend-${sizeOfLegend}`])}
			{...fieldsetProps}
		>
			{legendContent && (
				<div className={css.tooltipContainer}>
					<legend className={classes(css.legend, legendClassName, css[`legend-${sizeOfLegend}`])}>
						<span className={css.legendText}>{legendContent}</span>
						{infoTooltip && (
							<>
								&nbsp;
								<Icon
									className={css.tooltip}
									tooltip={infoTooltip}
									marginSize='small'
									size='small'
									color='blue'
									type='Info'
								/>
							</>
						)}
					</legend>
				</div>
			)}
			{children}
		</fieldset>
	);
};
