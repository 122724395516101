import { PageFrame } from 'components/PageFrame/PageFrame';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import React from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import css from './CostsView.module.scss';
import { useParams } from 'react-router';
import { Link } from 'components/Link/Link';
import { ButtonPagination } from 'components/ButtonPagination/ButtonPagination';
import { formatAsUSD } from 'common/utils/formatAsUSD';
import classes from 'classnames';
import { Table } from 'components/Table/Table';
import { Container } from 'components/Container/Container';
import { Icon } from 'components/Icon/Icon';
import { ProgressHeader } from 'components/ProgressHeader/ProgressHeader';
import { clone } from 'ramda';

export const CostsSummaryView = () => {
	// @ts-ignore
	const { assessmentId } = useParams();
	const firestore = firebase.firestore();

	const costsDocument = firestore.collection('costs').doc(assessmentId);
	const [costs, loading] = useDocumentData(costsDocument);

	const costsTableHeaders = [
		{
			label: 'Roles (' + (costs?.items?.length || 0) + ')',
			property: 'label',
			className: css.costSummaryRoleColumn
		},
		{
			label: 'Quantity',
			property: 'quantity'
		},
		{
			label: 'Base Salary',
			property: 'salaries',
			renderFunction: (data: any) => <>{formatAsUSD(data.salaries)}</>
		},
		{
			label: 'Comp Per Team Member',
			property: 'compPerTeamMember',
			renderFunction: (data: any) => <>{formatAsUSD(data.compPerTeamMember)}</>,
			tooltip: 'The average annual pay for this role (compensation + benefits)'
		},
		{
			label: 'Total',
			property: 'totalComp',
			renderFunction: (data: any) => <>{formatAsUSD(data.totalComp)}</>,
			className: css.costSummaryTotalColumn,
			tooltip: 'The total annual pay for the respective group'
		}
	];
	const costsFutureTableHeaders = clone(costsTableHeaders);
	costsFutureTableHeaders[0].label = 'Roles (' + (costs?.futureItems?.length || 0) + ')';
	let currentTotalPanel = 0;
	const currentTeamCostsData = costs?.items?.map((item: any, index: number) => {
		const tempTotalComp =
			(parseFloat(item.salaries) + parseFloat(item.benefits)) * parseFloat(item.quantity);
		currentTotalPanel += parseFloat(item.panelPerFTE) * parseFloat(item.quantity) || 0;
		return {
			id: index,
			label: item.role,
			quantity: item.quantity,
			salaries: item.salaries,
			compPerTeamMember: parseFloat(item.salaries) + parseFloat(item.benefits),
			totalComp: tempTotalComp
		};
	});
	let futureTotalPanel = 0;

	const futureTeamCostsData = costs?.futureItems?.map((item: any, index: number) => {
		const tempTotalComp =
			(parseFloat(item.salaries) + parseFloat(item.benefits)) * parseFloat(item.quantity);
		futureTotalPanel += parseFloat(item.panelPerFTE) * parseFloat(item.quantity) || 0;

		return {
			id: index,
			label: item.role,
			quantity: item.quantity,
			salaries: item.salaries,
			compPerTeamMember: parseFloat(item.salaries) + parseFloat(item.benefits),
			totalComp: tempTotalComp
		};
	});

	return (
		<PageFrame fullWidth title='Cost Analysis' ContentTop={() => <ProgressHeader></ProgressHeader>}>
			<Container>
				<div className={css.summaryIntro}>
					<h2 className={css.accessibilityHeading}>
						<div className='h2'>You’ve completed your Cost Analysis</div>
						<div className={classes('h1', css.primaryHeading)}>View your high-level summary below:</div>
					</h2>
				</div>
			</Container>
			{!loading && (
				<>
					<div className={classes(css.summaryWrap, css['type-' + costs.type])}>
						{costs?.type === 'both' && (
							<div className={css.savingsPanel}>
								<Container className={css.savingsPanelContainer}>
									<div className={css.iconWrapper}>
										<Icon className={css.icon} type='Dollar' size='medium' color='white'></Icon>
									</div>
									<h3 className={classes('h2', css.savingsAmountText)}>
										Potential Future Savings Total:
										<span className={css.savingsAmountValue}>
											{formatAsUSD(
												costs?.totalCurrentTeamCosts -
													(costs?.totalFutureTeamCosts + costs?.totalFutureOtherCosts)
											)}
										</span>
									</h3>
								</Container>
							</div>
						)}
						<br />
						<Container>
							<div className={css.summarySplit}>
								{costs?.type !== 'future' && (
									<div>
										<h4 className='uppercase h2'>Current Primary Care Team Costs</h4>
										<div className={css.totalsPanel}>
											<div>
												<h5 className='h7'>Total Panel</h5>
												<div className={css.amount}>{currentTotalPanel}</div>
											</div>
											<div>
												<h5 className='h7'>Total Team Salary and Benefits</h5>
												<div className={css.amount}>{formatAsUSD(costs?.totalCurrentTeamCosts)}</div>
											</div>
											<div>
												<h5 className='h7'>Overall Current Costs</h5>
												<div className={css.amount}>{formatAsUSD(costs?.totalCurrentTeamCosts)}</div>
											</div>
										</div>
										<br />
										<h5 className='uppercase'>Current Costs Breakdown</h5>
										<Table headers={costsTableHeaders} data={currentTeamCostsData}></Table>
									</div>
								)}
								{costs?.type !== 'current' && (
									<div>
										<h2 className='uppercase'>Future Primary Care Team Costs</h2>
										<div className={css.totalsPanel}>
											<div>
												<h5 className='h7'>Total Panel</h5>
												<div className={css.amount}>{futureTotalPanel}</div>
											</div>
											<div>
												<h5 className='h7'>Total Team Salary and Benefits</h5>
												<div className={css.amount}>{formatAsUSD(costs?.totalFutureTeamCosts)}</div>
											</div>
											<div>
												<h5 className='h7'>Overall Future Costs</h5>
												<div className={css.amount}>
													{formatAsUSD(costs?.totalFutureOtherCosts + costs?.totalFutureTeamCosts)}
												</div>
											</div>
										</div>
										<br />

										<h5 className='uppercase'>Future Costs Breakdown</h5>
										<Table headers={costsFutureTableHeaders} data={futureTeamCostsData}></Table>
										<div className={css.futureInvestmentsSummary}>
											<h5 className='h7'>Future Investments</h5>
											<Icon
												className={css.icon}
												type={'Info'}
												size='small'
												color='blueLight'
												tooltip='Total additional investment costs to implementing value based care'
											></Icon>
											<div className={css.amount}>{formatAsUSD(costs?.totalFutureOtherCosts)}</div>
										</div>
									</div>
								)}
							</div>
						</Container>
					</div>
					<br />
					<br />

					<Container>
						<ButtonPagination showInstructions={false}>
							<Link
								type='secondary'
								to={
									'/assessment/' +
									assessmentId +
									'/costs/' +
									(costs?.type === 'current' ? 'current-team' : 'future-other')
								}
							>
								Back
							</Link>
							<Link type='primary' to={'/assessment/' + assessmentId}>
								Done
							</Link>
						</ButtonPagination>
					</Container>
				</>
			)}
		</PageFrame>
	);
};
