import { PageFrame } from 'components/PageFrame/PageFrame';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import React from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import css from './SummaryView.module.scss';
import { formatAsUSD } from 'common/utils/formatAsUSD';
import classes from 'classnames';
import { ProgressHeader } from 'components/ProgressHeader/ProgressHeader';
import { ButtonPagination } from 'components/ButtonPagination/ButtonPagination';
import { Tabs } from 'components/Tabs/Tabs';
import { Icon } from 'components/Icon/Icon';
import { Section } from 'components/Section/Section';
import { Link } from 'components/Link/Link';
import { Panel } from 'components/Panel/Panel';
import { abbreviateMonetaryValue } from 'common/utils/abbreviateMonetaryValue';
import { abbreviateNumberValue } from 'common/utils/abbreviateNumberValue';
import { clone } from 'ramda';
import highchartsAccessibility from 'highcharts/modules/accessibility';
highchartsAccessibility(Highcharts);

export const SummaryView = () => {
	// @ts-ignore
	const { assessmentId } = useParams();
	const firestore = firebase.firestore();

	const assessmentDocument = firestore.collection('assessments').doc(assessmentId);
	// eslint-disable-next-line
	const [assessment, loadingAssessment] = useDocumentData(assessmentDocument);

	const costsDocument = firestore.collection('costs').doc(assessmentId);
	const [costs, loadingCosts] = useDocumentData(costsDocument);

	const revenueDocument = firestore.collection('revenue').doc(assessmentId);
	const [revenue, loadingRevenue] = useDocumentData(revenueDocument);

	const compensationChartOptions = {
		chart: {
			type: 'bar'
		},
		title: {
			text: 'Change in Primary Care Team Costs By Role'
		},
		accessibility: {
			description: 'Change in Primary Care Team Costs By Role'
		},
		colors: ['#BEC4D1', '#385FB2'],
		xAxis: {
			type: 'category',
			reversed: true,
			labels: {
				step: 1
			},
			gridLineColor: '#F2F3F4',
			lineColor: '#F2F3F4'
		},
		yAxis: {
			title: {
				text: 'Total Compensation'
			},
			labels: {
				rotation: 0,
				// @ts-ignore
				formatter: function() {
					// @ts-ignore
					return abbreviateMonetaryValue(Math.abs(this.value));
				}
			},
			gridLineColor: '#F2F3F4'
		},
		plotOptions: {
			bar: {
				dataLabels: {
					enabled: true,
					// @ts-ignore
					formatter: function() {
						// @ts-ignore
						return abbreviateMonetaryValue(Math.abs(this.point.y));
					}
				}
			},
			series: {
				stacking: 'normal',
				pointWidth: 17
				// color: '#BEC4D1'
			}
		},
		legend: {
			layout: 'horizontal',
			align: 'left',
			verticalAlign: 'bottom',
			floating: false,
			shadow: false
		},
		tooltip: {
			borderColor: '#1F3160',
			backgroundColor: '#F2F3F4',
			shadow: false,
			// @ts-ignore
			formatter: function() {
				console.log('this', this);
				return (
					'<b>' +
					// @ts-ignore
					this.key +
					'</b><br/>' +
					// @ts-ignore
					this.series.name +
					': ' +
					// @ts-ignore
					abbreviateMonetaryValue(Math.abs(this.point.y))
				);
			}
		},
		credits: {
			enabled: false
		},
		series: [
			{
				name: 'Current',
				dataLabels: {
					enabled: false
				},
				data: costs?.items?.map((item: any) => {
					return [
						item.role,
						-1 * (parseFloat(item.salaries) + parseFloat(item.benefits)) * parseFloat(item.quantity)
					];
				})
			},
			{
				name: 'Future',
				dataLabels: {
					enabled: false
				},
				data: costs?.futureItems?.map((item: any) => {
					return [
						item.role,
						(parseFloat(item.salaries) + parseFloat(item.benefits)) * parseFloat(item.quantity)
					];
				})
			}
		]
	};
	const livesNeededChartOptions = clone(compensationChartOptions);

	livesNeededChartOptions.title.text = 'Number of New Members Needed by Payer Type';
	livesNeededChartOptions.accessibility.description = 'Number of New Members Needed by Payer Type';
	livesNeededChartOptions.colors = ['#385FB2', '#BEC4D1'];

	livesNeededChartOptions.yAxis.title.text = 'Member Lives';
	livesNeededChartOptions.yAxis.labels.formatter = function() {
		// @ts-ignore
		return abbreviateNumberValue(Math.abs(this.value));
	};
	livesNeededChartOptions.plotOptions.bar.dataLabels.formatter = function() {
		// @ts-ignore
		return abbreviateNumberValue(Math.abs(this.point.y));
	};
	livesNeededChartOptions.plotOptions.series.stacking = 'normal';
	livesNeededChartOptions.tooltip.formatter = function() {
		return (
			'<b>' +
			// @ts-ignore
			this.key +
			' ' +
			'</b><br/>' +
			'Lives Needed: ' +
			// @ts-ignore
			abbreviateNumberValue(Math.abs(this.point.y).toFixed(0))
		);
	};
	let isLivesNeededChartHidden = true;
	livesNeededChartOptions.series = [
		{
			name: 'Lives Needed',
			dataLabels: {
				enabled: false
			},
			data: revenue?.contractTypes?.map((contractType: any) => {
				const payers = revenue?.payers?.filter((payer: any) => payer.contractType === contractType);
				let tempTotalLives = 0;
				let tempRevenue = 0;
				let tempTotalPotentialChangeInRevenue = 0;

				payers.forEach((payer: any) => {
					tempTotalLives += parseFloat(payer.memberLives) || 0;

					tempRevenue +=
						(parseFloat(payer.valueBasedNetRevenue) || 0) +
						(parseFloat(payer.netFFSRevenueInpatient) || 0) +
						(parseFloat(payer.netFFSRevenueOutpatient) || 0) +
						(parseFloat(payer.netFFSRevenueProfessional) || 0);

					const memberLives = parseFloat(payer.memberLives) || 0;
					payer.utilizations?.forEach((utilization: any) => {
						const futureUtilizations = parseFloat(utilization.futureUtilizationPer1000) || 0;
						const revenue = parseFloat(utilization.revenue) || 0;
						const currentUtilizations = parseFloat(utilization.currentUtilizationsAmount) || 0;

						tempTotalPotentialChangeInRevenue +=
							(Math.round(futureUtilizations / 1000
								* memberLives) -
							Math.round(Math.round(currentUtilizations / memberLives * 1000) / 1000
								* memberLives)) *
							(revenue / currentUtilizations) || 0;
					});
				});

				const newLivesToBreakEven =
					tempTotalPotentialChangeInRevenue > 0
						? 0
						: (tempTotalPotentialChangeInRevenue * -1) / (tempRevenue / tempTotalLives);

				if (newLivesToBreakEven > 0) {
					isLivesNeededChartHidden = false;
				}
				return [contractType, newLivesToBreakEven];
			})
		}
	];

	let totalLives = 0;
	let ffsNetRevenue = 0;
	let valueBasedNetRevenue = 0;
	let totalPotentialChangeInRevenue = 0;

	revenue?.payers?.forEach((item: any) => {
		totalLives += item.memberLives || 0;
		ffsNetRevenue +=
			(item.netFFSRevenueInpatient || 0) +
			(item.netFFSRevenueOutpatient || 0) +
			(item.netFFSRevenueProfessional || 0);
		valueBasedNetRevenue += item.valueBasedNetRevenue || 0;

		item.utilizations?.forEach((utilization: any) => {
			totalPotentialChangeInRevenue +=
				(Math.round(utilization.futureUtilizationPer1000 / 1000
					* item.memberLives) -
				Math.round(Math.round(utilization.currentUtilizationsAmount / item.memberLives * 1000) / 1000
					* item.memberLives)) *
				(utilization.revenue / utilization.currentUtilizationsAmount) || 0;
		});
	});

	const totalNetRevenue = (valueBasedNetRevenue || 0) + (ffsNetRevenue || 0);
	const pmpmRevenue = totalNetRevenue / totalLives / 12;

	const TotalsTable = () => (
		<>
			<h3 className={classes(css.uppercase, 'h2')}>Revenue Overview</h3>
			<div className='tableWrapper'>
				<table className={css.totalsTable}>
					<tbody>
						<tr>
							<td>
								<h4 className='h7'>Total Member Lives</h4>
								<div className={css.amount}>{totalLives || 0}</div>
							</td>
							<td>
								<h4 className='h7'>Total FFS Revenue</h4>
								<div className={css.amount}>{formatAsUSD(ffsNetRevenue || 0)}</div>
							</td>
							<td>
								<h4 className='h7'>Total Value Based Revenue</h4>
								<div className={css.amount}>{formatAsUSD(valueBasedNetRevenue || 0)}</div>
							</td>
							<td>
								<h4 className='h7'>Total Revenue PMPM</h4>
								<div className={css.amount}>{formatAsUSD(pmpmRevenue || 0)}</div>
							</td>
							<td>
								<h4 className='h7'>Overall Total Revenue</h4>
								<div className={css.amount}>{formatAsUSD(totalNetRevenue || 0)}</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</>
	);

	const tabs = [
		{
			label: 'All',
			location: '/assessment/' + assessmentId + '/comprehensive-summary/',
			route: '/assessment/' + assessmentId + '/comprehensive-summary/',
			component: () => (
				<div className={css.summaryTabContent}>
					<TotalsTable></TotalsTable>

					<h3 className={classes(css.uppercase, 'h5')}>Revenue: Payer Type Break Down</h3>
					<div className='tableWrapper'>
						<table className={css.totalsTable}>
							<thead>
								<tr>
									<th>
										<div className={css.thInner}>
											<div className={css.thLabel}>Type</div>
										</div>
									</th>
									<th>
										<div className={css.thInner}>
											<div className={css.thLabel}>Total Lives</div>
											<Icon
												className={css.icon}
												type={'Info'}
												size='small'
												color='blueLight'
												tooltip='The number of attributed or assigned members in the contract.'
											></Icon>
										</div>
									</th>
									<th>
										<div className={css.thInner}>
											<div className={css.thLabel}>FFS net revenue</div>
											<Icon
												className={css.icon}
												type={'Info'}
												size='small'
												color='blueLight'
												tooltip='Total Net Fee-For-Service (FFS) = Inpatient + Outpatient + Professional'
											></Icon>
										</div>
									</th>
									<th>
										<div className={css.thInner}>
											<div className={css.thLabel}>Value Based Net revenue</div>
											<Icon
												className={css.icon}
												type={'Info'}
												size='small'
												color='blueLight'
												tooltip='Revenue from value-based contract performance not included in previous revenue columns.'
											></Icon>
										</div>
									</th>
									<th>
										<div className={css.thInner}>
											<div className={css.thLabel}>Total Net revenue</div>
											<Icon
												className={css.icon}
												type={'Info'}
												size='small'
												color='blueLight'
												tooltip='Revenue is the total annual amount paid for the event type for the payer. '
											></Icon>
										</div>
									</th>
									<th>
										<div className={css.thInner}>
											<div className={css.thLabel}>% of Total revenue</div>
											<Icon
												className={css.icon}
												type={'Info'}
												size='small'
												color='blueLight'
												tooltip='This is the net revenue of this contract compared to the total net revenue of the organization'
											></Icon>
										</div>
									</th>
									<th>
										<div className={css.thInner}>
											<div className={css.thLabel}>PMPM revenue</div>
											<Icon
												className={css.icon}
												type={'Info'}
												size='small'
												color='blueLight'
												tooltip='This is the monthly net revenue per each individual member per month'
											></Icon>
										</div>
									</th>
									<th className={css.totalColumn}>
										<div className={css.thInner}>
											<div className={css.thLabel}>Total Potential Change in Revenue</div>
											<Icon
												className={css.icon}
												type={'Info'}
												size='small'
												color='blueLight'
												tooltip='This is the change in fee-for-service revenue anticipated due to the potential decrease in high-cost utilizations.'
											></Icon>
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
								{revenue?.contractTypes?.map((contractType: any, index: number) => {
									const payers = revenue?.payers?.filter(
										(payer: any) => payer.contractType === contractType
									);
									let tempTotalLives = 0;
									let tempFfsNetRevenue = 0;
									let tempValueBasedNetRevenue = 0;
									let tempTotalPotentialChangeInRevenue = 0;

									payers.forEach((payer: any) => {
										tempTotalLives += payer.memberLives || 0;
										tempFfsNetRevenue +=
											(payer.netFFSRevenueInpatient || 0) +
											(payer.netFFSRevenueOutpatient || 0) +
											(payer.netFFSRevenueProfessional || 0);
										tempValueBasedNetRevenue += payer.valueBasedNetRevenue || 0;

										payer.utilizations?.forEach((utilization: any) => {
											tempTotalPotentialChangeInRevenue +=
												(Math.round(utilization.futureUtilizationPer1000 / 1000
													* payer.memberLives) -
												Math.round(Math.round(utilization.currentUtilizationsAmount / payer.memberLives * 1000) / 1000
													* payer.memberLives)) *
												(utilization.revenue / utilization.currentUtilizationsAmount) || 0;
										});
									});
									tempTotalPotentialChangeInRevenue += tempValueBasedNetRevenue || 0;
									const tempTotalNetRevenue = (tempValueBasedNetRevenue || 0) + (tempFfsNetRevenue || 0);
									const tempPmpmRevenue = tempTotalNetRevenue / tempTotalLives / 12;
									const tempPercentOfTotalRevenue = tempTotalNetRevenue / totalNetRevenue;
									return (
										<tr key={index}>
											<td>{contractType}</td>
											<td>{tempTotalLives || 0}</td>
											<td>{formatAsUSD(tempFfsNetRevenue || 0)}</td>
											<td>{formatAsUSD(tempValueBasedNetRevenue || 0)}</td>
											<td>{formatAsUSD(tempTotalNetRevenue || 0)}</td>
											<td>{((tempPercentOfTotalRevenue || 0) * 100).toFixed(2) + '%'}</td>
											<td>{formatAsUSD(tempPmpmRevenue || 0)}</td>
											<td className={css.totalColumn}>
												{formatAsUSD(tempTotalPotentialChangeInRevenue || 0)}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			)
		}
	];
	revenue?.contractTypes?.forEach((contractType: any) => {
		const startingState = {totalLives:0, ffsNetRevenue:0, valueBasedNetRevenue:0, netRevenue:0, pmpmRevenue: 0 }
		const tabSummaryData = revenue?.payers?.filter((payer: any) => (payer.contractType === contractType))
		.reduce(
			(previousValue: typeof startingState, payer: any) => {
				const totalLives = payer.memberLives || 0;
				const ffsNetRevenue = 
					(payer.netFFSRevenueInpatient || 0) +
					(payer.netFFSRevenueOutpatient || 0) +
					(payer.netFFSRevenueProfessional || 0);
				const valueBasedNetRevenue = payer.valueBasedNetRevenue || 0;
				
				const netRevenue = (valueBasedNetRevenue || 0) + (ffsNetRevenue || 0);
				const pmpmRevenue = netRevenue / totalLives / 12;
				return {
					totalLives: previousValue.totalLives + totalLives,
					ffsNetRevenue: previousValue.ffsNetRevenue + ffsNetRevenue,
					valueBasedNetRevenue: previousValue.valueBasedNetRevenue + valueBasedNetRevenue,
					netRevenue: previousValue.netRevenue + netRevenue,
					pmpmRevenue: previousValue.pmpmRevenue + pmpmRevenue
				}
			}
			, startingState
		)

		tabs.push({
			label: contractType,
			location:
				'/assessment/' +
				assessmentId +
				'/comprehensive-summary/' +
				contractType.toLowerCase().replace(' ', '-'),
			route:
				'/assessment/' +
				assessmentId +
				'/comprehensive-summary/' +
				contractType.toLowerCase().replace(' ', '-'),
			component: () => (
				<div className={css.summaryTabContent}>
					<h3 className={classes(css.uppercase, 'h2')}>Revenue Overview</h3>
					<div className='tableWrapper'>
						<table className={css.totalsTable}>
							<tbody>
								<tr>
									<td>
										<h4 className='h7'>Total Member Lives</h4>
										<div className={css.amount}>{tabSummaryData.totalLives}</div>
									</td>
									<td>
										<h4 className='h7'>Total FFS Revenue</h4>
										<div className={css.amount}>{formatAsUSD(tabSummaryData.ffsNetRevenue)}</div>
									</td>
									<td>
										<h4 className='h7'>Total Value Based Revenue</h4>
										<div className={css.amount}>{formatAsUSD(tabSummaryData.valueBasedNetRevenue || 0)}</div>
									</td>
									<td>
										<h4 className='h7'>Total Revenue PMPM</h4>
										<div className={css.amount}>{formatAsUSD(tabSummaryData.pmpmRevenue || 0)}</div>
									</td>
									<td>
										<h4 className='h7'>Overall Total Revenue</h4>
										<div className={css.amount}>{formatAsUSD(tabSummaryData.netRevenue || 0)}</div>
									</td>
								</tr>

							</tbody>
						</table>
					</div>
					
					<h3 className={classes(css.uppercase, 'h5')}>Revenue: {contractType} Payer Break Down</h3>

					<div className='tableWrapper'>
						<table className={css.totalsTable}>
							<thead>
								<tr>
									<th>
										<div className={css.thInner}>
											<div className={css.thLabel}>Payer Name</div>
										</div>
									</th>
									<th>
										<div className={css.thInner}>
											<div className={css.thLabel}>Total Lives</div>
											<Icon
												className={css.icon}
												type={'Info'}
												size='small'
												color='blueLight'
												tooltip='The number of attributed or assigned members in the contract.'
											></Icon>
										</div>
									</th>
									<th>
										<div className={css.thInner}>
											<div className={css.thLabel}>FFS net revenue</div>
											<Icon
												className={css.icon}
												type={'Info'}
												size='small'
												color='blueLight'
												tooltip='Total Net Fee-For-Service (FFS) = Inpatient + Outpatient + Professional'
											></Icon>
										</div>
									</th>
									<th>
										<div className={css.thInner}>
											<div className={css.thLabel}>Value Based Net revenue</div>
											<Icon
												className={css.icon}
												type={'Info'}
												size='small'
												color='blueLight'
												tooltip='Revenue from value-based contract performance not included in previous revenue columns.'
											></Icon>
										</div>
									</th>
									<th>
										<div className={css.thInner}>
											<div className={css.thLabel}>TOTAL Net revenue</div>
											<Icon
												className={css.icon}
												type={'Info'}
												size='small'
												color='blueLight'
												tooltip='Revenue is the total annual amount paid for the event type for the payer.'
											></Icon>
										</div>
									</th>
									<th>
										<div className={css.thInner}>
											<div className={css.thLabel}>% of Total revenue</div>
											<Icon
												className={css.icon}
												type={'Info'}
												size='small'
												color='blueLight'
												tooltip='This is the net revenue of this contract compared to the total net revenue of the organization'
											></Icon>
										</div>
									</th>
									<th>
										<div className={css.thInner}>
											<div className={css.thLabel}>PMPM revenue</div>
											<Icon
												className={css.icon}
												type={'Info'}
												size='small'
												color='blueLight'
												tooltip='This is the monthly net revenue per each individual member per monthtooltip Message'
											></Icon>
										</div>
									</th>
									<th className={css.totalColumn}>
										<div className={css.thInner}>
											<div className={css.thLabel}>Total Potential change in Revenue</div>
											<Icon
												className={css.icon}
												type={'Info'}
												size='small'
												color='blueLight'
												tooltip='This is the change in fee-for-service revenue anticipated due to the potential decrease in high-cost utilizations.'
											></Icon>
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
								{revenue?.payers?.map((payer: any, index: number) => {
									if (payer.contractType !== contractType) {
										return <></>;
									}
									let tempTotalLives = 0;
									let tempFfsNetRevenue = 0;
									let tempValueBasedNetRevenue = 0;
									let tempTotalPotentialChangeInRevenue = 0;

									tempTotalLives += payer.memberLives || 0;
									tempFfsNetRevenue +=
										(payer.netFFSRevenueInpatient || 0) +
										(payer.netFFSRevenueOutpatient || 0) +
										(payer.netFFSRevenueProfessional || 0);
									tempValueBasedNetRevenue += payer.valueBasedNetRevenue || 0;

									payer.utilizations?.forEach((utilization: any) => {
										tempTotalPotentialChangeInRevenue +=
											(Math.round(utilization.futureUtilizationPer1000 / 1000
												* payer.memberLives) -
											Math.round(Math.round(utilization.currentUtilizationsAmount / payer.memberLives * 1000) / 1000
												* payer.memberLives)) *
											(utilization.revenue / utilization.currentUtilizationsAmount) || 0;
									});
									tempTotalPotentialChangeInRevenue += tempValueBasedNetRevenue || 0;
									const tempTotalNetRevenue = (tempValueBasedNetRevenue || 0) + (tempFfsNetRevenue || 0);
									const tempPmpmRevenue = tempTotalNetRevenue / tempTotalLives / 12;
									const tempPercentOfTotalRevenue = tempTotalNetRevenue / totalNetRevenue;
									return (
										<tr key={index}>
											<td>{payer.name}</td>
											<td>{tempTotalLives || 0}</td>
											<td>{formatAsUSD(tempFfsNetRevenue || 0)}</td>
											<td>{formatAsUSD(tempValueBasedNetRevenue || 0)}</td>
											<td>{formatAsUSD(tempTotalNetRevenue || 0)}</td>
											<td>{((tempPercentOfTotalRevenue || 0) * 100).toFixed(2) + '%'}</td>
											<td>{formatAsUSD(tempPmpmRevenue || 0)}</td>
											<td className={css.totalColumn}>
												{formatAsUSD(tempTotalPotentialChangeInRevenue || 0)}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			)
		});
	});

	return (
		<>
			{!loadingAssessment && !loadingCosts && !loadingRevenue && (
				<PageFrame title='Comprehensive Summary' ContentTop={() => <ProgressHeader></ProgressHeader>}>
					<Section></Section>
					<Section>
						<h2 className={css.uppercase}>Overview</h2>
						<div className={css.totalsPanel}>
							<div>
								<div className={css.amount}>
									{formatAsUSD(
										costs?.totalFutureTeamCosts +
											costs?.totalFutureOtherCosts -
											costs?.totalCurrentTeamCosts || 0
									)}
								</div>
								<div className='h7'>Change In Care Team Costs</div>
							</div>
							<div>
								<div className={css.amount}>
									{formatAsUSD(totalPotentialChangeInRevenue + valueBasedNetRevenue || 0)}
								</div>
								<div className='h7'>Change In Revenue (FFS + VBC Revenue)</div>
							</div>
							<div>
								<div className={css.amount}>
									{formatAsUSD(
										costs?.totalFutureTeamCosts +
											costs?.totalFutureOtherCosts -
											costs?.totalCurrentTeamCosts +
											totalPotentialChangeInRevenue +
											valueBasedNetRevenue || 0
									)}
								</div>
								<div className='h7'>Total Estimated Return (Cost + Revenue)</div>
							</div>
							<div>
								<div className={css.amount}>
									{formatAsUSD(
										(costs?.totalFutureTeamCosts +
											costs?.totalFutureOtherCosts -
											costs?.totalCurrentTeamCosts +
											totalPotentialChangeInRevenue +
											valueBasedNetRevenue) /
											totalLives /
											12 || 0
									)}
								</div>
								<div className='h7'>Total Estimated Return PMPM</div>
							</div>
						</div>
					</Section>
					<Section>
						<h2 className={css.uppercase}>Trends</h2>
						<div className={css.chartsSplit}>
							<Panel>
								<HighchartsReact highcharts={Highcharts} options={compensationChartOptions} />
							</Panel>
							{!isLivesNeededChartHidden && (
								<Panel>
									<HighchartsReact highcharts={Highcharts} options={livesNeededChartOptions} />
								</Panel>
							)}
						</div>
					</Section>

					<Section>
						<h2 className={css.uppercase}>Breakdown</h2>
						<div>
							<Tabs navigationItems={tabs}></Tabs>
						</div>
					</Section>

					<ButtonPagination showInstructions={false}>
						<Link type='primary' to={'/assessment/' + assessmentId}>
							Done
						</Link>
					</ButtonPagination>
				</PageFrame>
			)}
		</>
	);
};
