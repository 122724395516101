import React, { useRef, useState, useEffect } from 'react';
import css from './Tabs.module.scss';
import classes from 'classnames';
import { TNavigationItems, TNavigationItem } from 'common/types/TNavigationItems';

import { Button } from 'components/Button/Button';

interface ITabsProps {
	className?: string;
	navigationItems: TNavigationItems;
}
interface ITabItemProps {
	index: number;
	isSelected: boolean;
	navigationItem: TNavigationItem;
	handleKeyup: (event: React.KeyboardEvent<HTMLAnchorElement>, navigationItem: TNavigationItem) => void;
	setRefByIndex: (ref: React.MutableRefObject<HTMLElement>, index: number) => void;
	onClick: any;
}

const TabItem: React.FC<ITabItemProps> = props => {
	const { onClick, index, isSelected, navigationItem, handleKeyup, setRefByIndex } = props;
	const ref = useRef(null);
	useEffect(() => {
		setRefByIndex(ref, index);
	}, [setRefByIndex, index]);
	return (
		<li key={index} role='presentation'>
			<Button
				aria-selected={isSelected}
				role='tab'
				type='link'
				ref={ref}
				tabIndex={isSelected === true ? 0 : -1}
				onKeyUp={(event: React.KeyboardEvent<HTMLAnchorElement>) => handleKeyup(event, navigationItem)}
				onClick={onClick}
				className={isSelected && css.selected}
			>
				{navigationItem.label}
			</Button>
		</li>
	);
};

export const Tabs: React.FC<ITabsProps> = props => {
	const { className, navigationItems } = props;

	const [refs, setRefs] = useState([]);
	const [selectedItemIndex, setSelectedItemIndex] = useState(0);
	const setRefByIndex = (ref: React.MutableRefObject<HTMLElement>, index: number) => {
		refs[index] = ref;
		setRefs(refs);
	};

	const selectTab = (navigationItem: TNavigationItem) => {
		const index = navigationItems.indexOf(navigationItem);
		refs[index].current.focus();
		setSelectedItemIndex(index);
	};

	const previousTab = (navigationItem: TNavigationItem) => {
		const index = navigationItems.indexOf(navigationItem);
		if (index > 0) {
			selectTab(navigationItems[index - 1]);
		} else {
			selectTab(navigationItems[navigationItems.length - 1]);
		}
	};

	const nextTab = (navigationItem: TNavigationItem) => {
		const index = navigationItems.indexOf(navigationItem);
		if (index < navigationItems.length - 1) {
			selectTab(navigationItems[index + 1]);
		} else {
			selectTab(navigationItems[0]);
		}
	};

	const handleKeyup = (
		event: React.KeyboardEvent<HTMLAnchorElement>,
		navigationItem: TNavigationItem
	) => {
		event.preventDefault();
		if (event.which === 13) {
			selectTab(navigationItem);
		} else if (event.which === 37) {
			previousTab(navigationItem);
		} else if (event.which === 39) {
			nextTab(navigationItem);
		}
	};

	const CurrentTabContent = navigationItems[selectedItemIndex].component;
	return (
		<>
			<div className={css.scrollContainer}>
				<ul role='tablist' className={classes(css.tabs, className)}>
					{navigationItems.map((navigationItem: TNavigationItem, index: number) => {
						const isSelected = selectedItemIndex === index;
						return (
							<TabItem
								key={index}
								isSelected={isSelected}
								index={index}
								navigationItem={navigationItem}
								handleKeyup={handleKeyup}
								setRefByIndex={setRefByIndex}
								onClick={() => setSelectedItemIndex(index)}
							></TabItem>
						);
					})}
				</ul>
			</div>
			<CurrentTabContent history={undefined} location={undefined} match={undefined}></CurrentTabContent>
		</>
	);
};
