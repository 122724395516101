const formatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 0
});

const formatterWithDecimals = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 2
});

export const formatAsUSD = (value: number, showDecimals?: boolean) => {
	if (showDecimals) {
		return value ? formatterWithDecimals.format(value) : '$0.00';
	} else {
		return value ? formatter.format(value) : '$0';
	}
};
