import { Button } from 'components/Button/Button';
import { PageFrame } from 'components/PageFrame/PageFrame';
import { Section } from 'components/Section/Section';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { Field, FieldArray, Form, Formik, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import css from './CostsView.module.scss';
import { useHistory, useParams } from 'react-router';
import { ButtonPagination } from 'components/ButtonPagination/ButtonPagination';
import { Link } from 'components/Link/Link';
import classes from 'classnames';
import { Icon } from 'components/Icon/Icon';
import { ProgressHeader } from 'components/ProgressHeader/ProgressHeader';
import { getUniqueId } from 'common/utils/getUniqueId';
import { focusOnId } from 'common/utils/focusOnId';
import { removeFrontendIdFromData } from 'common/utils/removeFrontendIdFromData';

interface IValues {
	assumedBenefitsPercentage: number;
	items: {
		uniqueId?: string;
		role: string;
		quantity: number;
		panelPerFTE: number;
		salaries: number;
		additionalBenefitCost: number;
		benefits: number;
		isPreSelected?: boolean;
	}[];
	totalCurrentTeamCosts: number;
}

export const CostsStepCurrentTeamView = () => {
	// @ts-ignore
	const { assessmentId } = useParams();
	const firestore = firebase.firestore();

	const costsDocument = firestore.collection('costs').doc(assessmentId);
	const [costs, loading] = useDocumentData(costsDocument);
	const history = useHistory();

	const updateCosts = async (data: any) => {
		try {
			await firestore
				.collection('costs')
				.doc(assessmentId)
				.set(
					{
						...data,
						updatedAt: firebase.firestore.FieldValue.serverTimestamp()
					},
					{ merge: true }
				)
				.then(() => {
					if (costs.type === 'current') {
						history.push('/assessment/' + assessmentId + '/costs/summary');
					} else {
						history.push('/assessment/' + assessmentId + '/costs/future-team');
					}
				});
		} catch (error) {
			throw new Error(error);
		}
	};
	const [isSubmitting, setIsSubmitting] = useState(false);

	return (
		<PageFrame
			title='Cost Analysis'
			ContentTop={() => <ProgressHeader></ProgressHeader>}
			sidebarContent={
				<>
					<strong>Role Data</strong>
					<p>Please enter the “other” care team job title in the “Enter Role” field. </p>
					<p>Click “Add Role” to include additional care team member job titles.</p>
					<strong>Quantity (Full-Time-Equivalent)</strong>
					<p>Please enter the number of Full Time Equivalent (FTE) team members for each role.</p>
					<strong>Panel per Full Time Equivalent (FTE)</strong>
					<p>
						Please enter the 12 or 18 month active panel for primary roles that are assigned their own
						patients.
					</p>
					<p>
						As an example, a typical active panel for traditional fee-for service primary care setting
						ranges between 1,800 and 2,400 patients; whereas value-based settings can manage larger
						patient panels.
					</p>
					<strong>Median Salary</strong>
					<p>Please enter the average base salary from current clinic records for each role.</p>
					<p>
						If there are significant differences in salaries within a role click “Add Role” to contrast
						variations.
					</p>
					<strong>Additional Benefit Cost</strong>
					<p>
						Please enter the cost of additional benefits other than base salary, which might be around
						30% of salary.
					</p>
				</>
			}
		>
			{!loading && (
				<Formik
					initialValues={{
						assumedBenefitsPercentage: costs?.assumedBenefitsPercentage || 31,
						items: costs?.items ||
							costs?.roles?.map((item: string) => ({
								role: item,
								// @ts-ignore
								quantity: '',
								// @ts-ignore
								panelPerFTE: '',
								// @ts-ignore
								salaries: '',
								// @ts-ignore
								additionalBenefitCost: '',
								// @ts-ignore
								benefits: '',
								isPreSelected: item === 'Other' ? false : true
							})) || [
								{
									role: '',
									quantity: '',
									panelPerFTE: '',
									salaries: '',
									additionalBenefitCost: '',
									benefits: ''
								}
							],
						totalCurrentTeamCosts: null
					}}
					validateOnChange={false}
					validateOnBlur={true}
					onSubmit={async (data: IValues) => {
						setIsSubmitting(true);
						await updateCosts(removeFrontendIdFromData(data, 'items'));
					}}
				>
					{props => (
						<CostsForm isSubmitting={isSubmitting} assessmentId={assessmentId} {...props}></CostsForm>
					)}
				</Formik>
			)}
		</PageFrame>
	);
};

const CostsForm = (props: any) => {
	const { isSubmitting } = props;
	const { values, setFieldValue } = useFormikContext<IValues>();
	useEffect(() => {
		let temptotalCurrentTeamCosts = 0;
		values.items.forEach((item, index) => {
			const tempBenefits =
				(item.salaries || 0) * values.assumedBenefitsPercentage * 0.01 +
					(item.additionalBenefitCost || 0) || 0;
			if (tempBenefits !== item.benefits) {
				setFieldValue(`items.${index}.benefits`, tempBenefits.toFixed(2));
			}
			if (item.salaries && item.quantity && tempBenefits) {
				temptotalCurrentTeamCosts += ((item.salaries || 0) + tempBenefits) * (item.quantity || 0);
			}
		});
		if (temptotalCurrentTeamCosts !== values.totalCurrentTeamCosts) {
			setFieldValue('totalCurrentTeamCosts', temptotalCurrentTeamCosts);
		}

		// eslint-disable-next-line
	}, [values]);

	// useEffect(() => {

	// });

	return (
		<Form>
			<Section>
				<p className='h2'>Current Costs</p>
				<h2 className='h1'>
					Tell us about your <span className='underline'>current</span> team by role:
				</h2>
				<br />
				<FieldArray name='items'>
					{({ remove, push }) => (
						<>
							<div className='tableWrapper'>
								<table cellPadding={0} cellSpacing={0} className={css.costItems}>
									<tbody>
										{values.items.length > 0 && (
											<>
												<tr className={css.costItemRow}>
													<th className={css.medium}>Role</th>
													<th>
														<div className={css.thInner}>
															<div className={css.text}>Quantity</div>
															<Icon
																className={css.icon}
																type={'Info'}
																size='small'
																color='blueLight'
																tooltip='Please enter the number of Full Time Equivalent (FTE) team members for each role.'
															></Icon>
														</div>
													</th>
													<th className={css.large}>
														<div className={css.thInner}>
															<div className={css.text}>Panel per full-time equivalent (FTE)</div>
															<Icon
																className={css.icon}
																type={'Info'}
																size='small'
																color='blueLight'
																tooltip='Please enter the 12 or 18 month active panel for primary roles that are assigned their own patients.As an example, a typical active panel for traditional fee-for service primary care setting ranges between 1,800 and 2,400 patients; whereas value-based settings can manage larger patient panels.'
															></Icon>
														</div>
													</th>
													<th>
														<div className={css.thInner}>
															<div className={css.text}>Median Salary</div>
															<Icon
																className={css.icon}
																type={'Info'}
																size='small'
																color='blueLight'
																tooltip='Please enter the average base salary from current clinic records for each role.If there are significant differences in salaries within a role click “Add Role” to contrast variations.'
															></Icon>
														</div>
													</th>
													<th>
														<div className={css.thInner}>
															<div className={css.text}>Additional Benefit Cost (optional)</div>
															<Icon
																className={css.icon}
																type={'Info'}
																size='small'
																color='blueLight'
																tooltip='Please enter the cost of additional benefits other than base salary, which might be around 30% of salary.'
															></Icon>
														</div>
													</th>
													<th className={css.small}>
														<div className='screenReaderText'>Remove Item</div>
													</th>
												</tr>
												{values.items.map((item, index) => (
													<tr className={css.costItemRow} key={index}>
														<td className={css.medium}>
															{item.isPreSelected ? (
																<strong>{item.role}</strong>
															) : (
																<Field
																	name={`items.${index}.role`}
																	placeholder={'Enter role'}
																	type='text'
																	id={item.uniqueId}
																></Field>
															)}
														</td>
														<td className={css.small}>
															<Field
																name={`items.${index}.quantity`}
																placeholder={'Enter quantity (#)'}
																type='number'
															></Field>
														</td>
														<td className={css.large}>
															<Field
																name={`items.${index}.panelPerFTE`}
																type='number'
																placeholder='Enter panel per FTE (#)'
																value={
																	item.role !== 'MD/DO' && item.role !== 'PA/NP' ? '' : item.panelPerFTE
																}
																disabled={item.role !== 'MD/DO' && item.role !== 'PA/NP'}
															></Field>
														</td>
														<td>
															<Field
																name={`items.${index}.salaries`}
																placeholder={'Enter salaries ($)'}
																step='1'
																type='number'
															></Field>
														</td>

														<td>
															<Field
																name={`items.${index}.additionalBenefitCost`}
																type='number'
																step='1'
																placeholder='Enter cost ($)'
																value={item.additionalBenefitCost}
															></Field>
														</td>

														<td className={classes(css.buttonWrap, css.smallest)}>
															<Button
																htmlType='button'
																type='control'
																size='small'
																aria-label='Remove row'
																iconRight={{ type: 'Cancel', color: 'blueLight', size: 'large' }}
																onClick={event => {
																	event.preventDefault();
																	remove(index);
																}}
															></Button>
														</td>
													</tr>
												))}
											</>
										)}
									</tbody>
								</table>
							</div>

							<Button
								htmlType='button'
								type='link'
								iconLeft={{ type: 'Plus', color: 'green' }}
								onClick={event => {
									const uniqueId = getUniqueId();
									event.preventDefault();
									push({ uniqueId, role: '', quantity: '', salaries: '', benefits: '' });
									focusOnId(uniqueId);
								}}
							>
								Add Role
							</Button>
						</>
					)}
				</FieldArray>
				{/* <br /> */}
				{/* <label>Total Current Costs (So Far)</label>
					<input disabled value={formatAsUSD(values.totalCurrentTeamCosts)}></input> */}

				<ButtonPagination>
					<Link type='secondary' to={'/assessment/' + props.assessmentId + '/costs/roles'}>
						Back
					</Link>
					<Button type='primary' loadingLabel='Saving' htmlType='submit' isLoading={isSubmitting}>
						Next
					</Button>
				</ButtonPagination>
			</Section>
		</Form>
	);
};
