import React from 'react';

/**
 * The copy is split up into two types of section: copy that only exists on a single page and copy that is shared across multiple pages. One type of content that is commonly shared across multiple pages is tooltip copy.
 */

// Shared Copy
// Set text to text to use as aria-role below to avoid eslint error // eslint-disable-next-line doesn't work in this instance for an unknown reason

export const Copy = {
	/**
	 * Browser Elements
	 *
	 * Located on:
	 * - PageFrame
	 */

	// HTMLTitle is the title that shows up in the browser tab
	HTMLTitleTemplate: (HTMLTitle: string, title: string) => `${HTMLTitle || title} | React Sandbox`,

	/**
	 * Components
	 *
	 * Components are used throughout the site
	 */

	// Alert
	componentAlertDismissButton: 'Dismiss Message',
	// AppFrame
	componentAppFrameSkipNavigation: 'Skip Navigation',

	// ComponentDropdownNotifications
	componentDropdownNotificationsTitle: 'Notifications',
	componentDropdownNotificationsMarkAllAsReadButton: 'Mark all as read',
	componentDropdownNotificationsViewAll: 'View all',
	componentDropdownNotificationsEmpty: () => (
		<>
			You currently have
			<br />
			no notifications.
		</>
	),
	componentDropdownNotificationsButtonScreenReader: (unreadNotificationAmount: number) =>
		`Notification Dropdown (${unreadNotificationAmount} Unread Notifications)`,

	// Button
	componentButtonLoadingDefault: 'Loading',

	// InputSearch
	componentInputSearchPlaceholder: 'Search',
	// GridItemDownload
	componentGridItemIntegrityLink: 'Verify integrity',
	// Icon
	componentIconTooltipMessageLabel: 'Tooltip Message.',
	// Modal
	componentModalErrorContentDefault: 'An error occurred please reload the page and try again.',
	componentModalScreenReaderDescription:
		'Beginning of dialog window. Escape will cancel and close the window.',
	// Pagination
	componentPaginationPageItemScreenReader: 'Page',
	componentPaginationAriaLabel: 'Pagination Navigation',
	componentPaginationPreviousPageAccessibilityLabel: (pageNumber: number) =>
		`Go to previous page, page ${pageNumber}`,
	componentPaginationNextPageAccessibilityLabel: (pageNumber: number) =>
		`Go to next page, page ${pageNumber}`,
	componentPaginationCurrentPageIndicatorAccessibilityLabel: (pageNumber: number) =>
		`Current page, page ${pageNumber}`,
	componentPaginationGoToPageAccessibilityLabel: (pageNumber: number) => `Go to page ${pageNumber}`,

	// Table
	componentTableRowColumnIndicationScreenReader: 'Row selection',
	componentTableMainSelectorSelectionAmountAllScreenReader:
		'All items in table selected. Click this button to unselect all items.',
	componentTableMainSelectorSelectionAmountPartialScreenReader:
		'Some items in table selected. Click this button to select all items.',
	componentTableMainSelectorSelectionAmountNoneScreenReader:
		'No items in table selected. Click this button to select all items.',
	componentTableSelectionLabelScreenReader: 'Is notification selected',
	// TableController
	componentTableControllerChangePageTitle: /* Ignored by most browsers */ (pageNumber: number) =>
		`Page ${pageNumber}`,
	componentTableControllerEmptySearch: (searchQuery: string) =>
		`We could not find a match for “${searchQuery}”. Please try another search.`,
	componentTableControllerSearchLabelScreenReader: 'Search',
	componentTableControllerTableTitle: (
		firstItemLocation: number,
		lastItemLocation: number,
		totalCount: number
	) => `Viewing ${firstItemLocation}–${lastItemLocation} of ${totalCount}`,

	/**
	 * Breadcrumb Labels
	 *
	 * These are used for the pagination links
	 */

	breadcrumbLabelUserManagement: 'User management',
	breadcrumbLabelAllCompanies: 'All companies',
	breadcrumbLabelAllUsers: 'All users',
	breadcrumbLabelAllDevices: 'All devices',
	breadcrumbLabelAllDuplicateFiles: 'All duplicate files',
	breadcrumbLabelAllCompanyFiles: 'All company files',

	/**
	 * Auth
	 *
	 */

	authAssessmentExpired: 'Your assessment has expired. Please sign in again.',
	authLoginError: 'There was an error logging in, please try again.'
};
