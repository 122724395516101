import React from 'react';
import './App.scss';
import { Routes } from 'routes';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { MessageProvider } from 'components/Message/MessageProvider';
import { ModalProvider } from 'components/Modal/ModalProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import firebase from 'firebase/app';

firebase.initializeApp({
	apiKey: 'AIzaSyAz5L1rhzRTpTUQvLS-kIsMYqLAvnTi-pA',
	authDomain: 'react-sandbox-b173a.firebaseapp.com',
	projectId: 'react-sandbox-b173a',
	storageBucket: 'react-sandbox-b173a.appspot.com',
	messagingSenderId: '112597046929',
	appId: '1:112597046929:web:f7b279f89f359bc541d130',
	measurementId: 'G-DEKR5F2TY9'
});

const App: React.FC = () => {
	return (
		<ErrorBoundary>
			<MessageProvider>
				<ModalProvider>
					<Router>
						<Routes></Routes>
					</Router>
				</ModalProvider>
			</MessageProvider>
		</ErrorBoundary>
	);
};

export const handleFirstTab = (event: KeyboardEvent) => {
	if (event.keyCode === 9) {
		// the "I am a keyboard user" key
		document.body.classList.add('user-is-tabbing');
		window.removeEventListener('keydown', handleFirstTab);
	}
};

window.addEventListener('keydown', handleFirstTab);

export default App;
