import { Button } from 'components/Button/Button';
import { PageFrame } from 'components/PageFrame/PageFrame';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import css from './CostsView.module.scss';
import { useHistory, useParams } from 'react-router';
import { CheckboxGroup } from 'components/Form/CheckboxGroup/CheckboxGroup';
import { ButtonPagination } from 'components/ButtonPagination/ButtonPagination';
import { Link } from 'components/Link/Link';
import { ProgressHeader } from 'components/ProgressHeader/ProgressHeader';
import { Alert } from 'components/Alert/Alert';

export const CostsStepRolesView = () => {
	// @ts-ignore
	const { assessmentId } = useParams();
	const firestore = firebase.firestore();

	const costsDocument = firestore.collection('costs').doc(assessmentId);
	const [costs, loading] = useDocumentData(costsDocument);

	const history = useHistory();

	const updateCosts = async (data: any) => {
		try {
			let tempRoles: any[] = [];
			data.primaryRoles.forEach((role: any) => tempRoles.push(role));
			data.additionalRoles.forEach((role: any) => tempRoles.push(role));
			data.roles = tempRoles;

			let tempFutureRoles: any[] = [];
			data.primaryRolesFuture.forEach((role: any) => tempFutureRoles.push(role));
			data.additionalRolesFuture.forEach((role: any) => tempFutureRoles.push(role));
			data.futureRoles = tempFutureRoles;

			if (costs.items && data.roles) {
				data.roles.forEach((role: any) => {
					if (costs.items && costs.items.filter((item: any) => item.role === role).length <= 0) {
						costs.items.push({
							role: role,
							quantity: 0,
							panelPerFTE: 0,
							salaries: 0,
							additionalBenefitCost: 0,
							benefits: 0,
							isPreSelected: role === 'Other' ? false : true
						});
					}
				});
			}

			if (costs.futureItems && data.futureRoles) {
				data.futureRoles.forEach((role: any) => {
					if (
						costs.futureItems &&
						costs.futureItems.filter((item: any) => item.role === role).length <= 0
					) {
						costs.futureItems.push({
							role: role,
							quantity: 0,
							panelPerFTE: 0,
							salaries: 0,
							additionalBenefitCost: 0,
							benefits: 0,
							isPreSelected: role === 'Other' ? false : true
						});
					}
				});
			}

			await firestore
				.collection('costs')
				.doc(assessmentId)
				.set(
					{
						...data,
						items: costs.items || null,
						futureItems: costs.futureItems || null,
						updatedAt: firebase.firestore.FieldValue.serverTimestamp()
					},
					{ merge: true }
				)
				.then(() => {
					if (costs.type === 'future') {
						history.push('/assessment/' + assessmentId + '/costs/future-team');
					} else {
						history.push('/assessment/' + assessmentId + '/costs/current-team');
					}
				});
		} catch (error) {
			throw new Error(error as string);
		}
		// }
	};

	const primaryRoleOptions = [
		{ id: 'MD/DO', label: 'MD/DO (incl. Peds, excl. OBGYN)' },
		{ id: 'PA/NP', label: 'Physicial Assistant / Nurse Practitioner (PA/NP)' }
	];

	const additionalRoleOptions = [
		{ id: 'Nurse', label: 'Nurse (RN, LPN)' },
		{ id: 'MA', label: 'Medical Assistant (MA)' },
		{ id: 'CM', label: 'Care Manager (CM)' },
		{ id: 'Behavioral Health', label: 'Behavioral Health (LCSW, MSW, therapist, counselor)' },
		{ id: 'Pharmacist', label: 'Pharmacist' },
		{ id: 'Other', label: 'Other' }
	];
	const [isSubmitting, setIsSubmitting] = useState(false);
	const validate = (values: any, props: any /* only available when using withFormik */) => {
		const errors = {};

		if (
			values.primaryRoles.length <= 0 &&
			values.additionalRoles.length <= 0 &&
			values.primaryRolesFuture.length <= 0 &&
			values.additionalRolesFuture.length <= 0
		) {
			// @ts-ignore
			errors.top = 'Required';
		}

		return errors;
	};
	return (
		<PageFrame
			title='Cost Analysis'
			ContentTop={() => <ProgressHeader></ProgressHeader>}
			sidebarContent={
				<>
					<strong>Team Make Up</strong>
					<p>
						Please select your primary care team roles from Family Medicine, Internal Medicine,
						Pediatrics, and Gerontology (please exclude OB/Gyn).
					</p>
					<strong>Primary Roles</strong>
					<p>Please select the billable provider roles in this section, such as MDs, DOs, and APPs.</p>
					<strong>Additional Roles</strong>
					<p>
						Care Managers may also be called Care Navigators, Care Coordinators, Social Workers, or
						Community Health Workers embedded in the practice.
					</p>
					<p>
						Select Other for care team members not otherwise listed and enter the job title on the
						following screen where “Enter Role” is located.
					</p>
				</>
			}
		>
			{!loading && (
				<Formik
					initialValues={{
						primaryRoles: costs?.primaryRoles || [],
						additionalRoles: costs?.additionalRoles || [],
						primaryRolesFuture: costs?.primaryRolesFuture || [],
						additionalRolesFuture: costs?.additionalRolesFuture || []
					}}
					validateOnChange={false}
					validateOnBlur={false}
					onSubmit={async data => {
						setIsSubmitting(true);
						await updateCosts(data);
					}}
					validate={validate as any}
				>
					{props => {
						return (
							<Form>
								{' '}
								<p className='h2'>
									{costs?.type === 'both'
										? 'Current and Future'
										: costs?.type === 'current'
										? 'Current'
										: 'Future'}{' '}
									Costs
								</p>
								<h2 className='h1'>
									Select all the roles that apply to your current and future primary care team below:
								</h2>
								<p className='h3'>
									<strong>Can’t find the role you are looking for below?</strong> You will have the
									option to add detail around other roles in the next step if needed.
								</p>
								{(props.errors as any).top && (
									<>
										<br />
										<Alert type='danger'>You must select at least one role</Alert>
									</>
								)}
								<br />
								<>
									{costs?.type !== 'future' && (
										<>
											<CheckboxGroup
												label='Current Primary Roles'
												name='primaryRoles'
												styleType='button'
												className={css.checkboxButtonGroup}
												items={primaryRoleOptions.map(item => ({
													label: item.label,
													name: 'primaryRoles',
													value: item.id,
													checked: props.values.primaryRoles.includes(item.id)
												}))}
												formikProps={props}
											></CheckboxGroup>
											<CheckboxGroup
												label='Current Additional Roles'
												name='additionalRoles'
												styleType='button'
												className={css.checkboxButtonGroup}
												items={additionalRoleOptions.map(item => ({
													label: item.label,
													name: 'additionalRoles',
													value: item.id,
													checked: props.values.additionalRoles.includes(item.id)
												}))}
												formikProps={props}
											></CheckboxGroup>
										</>
									)}
									{costs?.type === 'both' && <hr></hr>}
									{costs?.type !== 'current' && (
										<>
											<CheckboxGroup
												label='Future Primary Roles'
												name='primaryRolesFuture'
												styleType='button'
												className={css.checkboxButtonGroup}
												items={primaryRoleOptions.map(item => ({
													label: item.label,
													name: 'primaryRolesFuture',
													value: item.id,
													checked: props.values.primaryRolesFuture.includes(item.id)
												}))}
												formikProps={props}
											></CheckboxGroup>
											<CheckboxGroup
												label='Future Additional Roles'
												name='additionalRolesFuture'
												styleType='button'
												className={css.checkboxButtonGroup}
												items={additionalRoleOptions.map(item => ({
													label: item.label,
													name: 'additionalRolesFuture',
													value: item.id,
													checked: props.values.additionalRolesFuture.includes(item.id)
												}))}
												formikProps={props}
											></CheckboxGroup>
										</>
									)}
								</>
								<br />
								<br />
								<ButtonPagination>
									<Link type='secondary' to={'/assessment/' + assessmentId + '/costs'}>
										Back
									</Link>
									<Button type='primary' htmlType='submit' isLoading={isSubmitting}>
										Next
									</Button>
								</ButtonPagination>
							</Form>
						);
					}}
				</Formik>
			)}
		</PageFrame>
	);
};
