import { Button } from 'components/Button/Button';
import { PageFrame } from 'components/PageFrame/PageFrame';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
// import css from './CostsView.module.scss';
import { useHistory, useParams } from 'react-router';
import { InputRadio } from 'components/Form/InputRadio/InputRadio';
import { RadioGroup } from 'components/RadioGroup/RadioGroup';
import { Link } from 'components/Link/Link';
import { ButtonPagination } from 'components/ButtonPagination/ButtonPagination';
import { ProgressHeader } from 'components/ProgressHeader/ProgressHeader';
// import classes from 'classnames';

export const CostsStep0View = () => {
	// @ts-ignore
	const { assessmentId } = useParams();
	const firestore = firebase.firestore();

	const costsDocument = firestore.collection('costs').doc(assessmentId);
	const [costs, loading] = useDocumentData(costsDocument);

	const history = useHistory();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const updateCosts = async (data: any) => {
		try {
			await firestore
				.collection('costs')
				.doc(assessmentId)
				.set(
					{
						...data,
						updatedAt: firebase.firestore.FieldValue.serverTimestamp()
					},
					{ merge: true }
				)
				.then(() => {
					history.push('/assessment/' + assessmentId + '/costs/roles');
				});
		} catch (error) {
			setIsSubmitting(false);
			throw new Error(error);
		}
	};

	return (
		<>
			<PageFrame
				title='Cost Analysis'
				ContentTop={() => <ProgressHeader></ProgressHeader>}
				sidebarContent={
					<>
						<strong>Current vs Future Cost</strong>
						<p>
							Value-based contracts encourage a team approach to patient care where advanced practice
							providers (APP), pharmacists, and care navigators help address patient needs and allow for
							primary care providers to manage a larger panel of patients.
						</p>
					</>
				}
			>
				{!loading && (
					<Formik
						initialValues={{
							assumentBenefitPercentage: costs?.assumentBenefitPercentage || 31,
							type: costs?.type || 'both'
						}}
						validateOnChange={false}
						validateOnBlur={false}
						onSubmit={async data => {
							setIsSubmitting(true);
							await updateCosts(data);
						}}
					>
						{props => {
							return (
								<Form>
									<p className='h2'>Let’s build your team to help us better understand your costs</p>
									<h2 className='h1'>
										Do you want to take a look at both your current &amp; future primary care team costs
										today?
									</h2>
									<br />

									<RadioGroup>
										<InputRadio
											// type='button'
											name='type'
											label='Yes both'
											value='both'
											checked={props.values.type === 'both'}
											formikProps={props}
										/>
										<InputRadio
											// type='button'
											name='type'
											label='No just current costs'
											value='current'
											checked={props.values.type === 'current'}
											formikProps={props}
										/>
										<InputRadio
											// type='button'
											name='type'
											label='No just future costs'
											value='future'
											checked={props.values.type === 'future'}
											formikProps={props}
										/>
									</RadioGroup>

									<ButtonPagination>
										<Link type='secondary' to={'/assessment/' + assessmentId}>
											Back
										</Link>
										<Button type='primary' htmlType='submit' isLoading={isSubmitting}>
											Next
										</Button>
									</ButtonPagination>
								</Form>
							);
						}}
					</Formik>
				)}
			</PageFrame>
		</>
	);
};
