import { Button } from 'components/Button/Button';
import { PageFrame } from 'components/PageFrame/PageFrame';
import { Section } from 'components/Section/Section';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { Field, FieldArray, Form, Formik, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import css from './CostsView.module.scss';
import { useHistory, useParams } from 'react-router';
import { ButtonPagination } from 'components/ButtonPagination/ButtonPagination';
import { Link } from 'components/Link/Link';
import classes from 'classnames';
import { ProgressHeader } from 'components/ProgressHeader/ProgressHeader';
import { Icon } from 'components/Icon/Icon';
import { getUniqueId } from 'common/utils/getUniqueId';
import { focusOnId } from 'common/utils/focusOnId';
import { removeFrontendIdFromData } from 'common/utils/removeFrontendIdFromData';

export const CostsStepFutureOtherView = () => {
	// @ts-ignore
	const { assessmentId } = useParams();
	const firestore = firebase.firestore();

	const costsDocument = firestore.collection('costs').doc(assessmentId);
	const [costs, loading] = useDocumentData(costsDocument);
	const history = useHistory();

	const updateCosts = async (data: any) => {
		try {
			await firestore
				.collection('costs')
				.doc(assessmentId)
				.set(
					{
						...data,
						updatedAt: firebase.firestore.FieldValue.serverTimestamp()
					},
					{ merge: true }
				)
				.then(() => {
					history.push('/assessment/' + assessmentId + '/costs/summary');
				});
		} catch (error) {
			throw new Error(error);
		}
	};
	const [isSubmitting, setIsSubmitting] = useState(false);

	return (
		<PageFrame
			title='Cost Analysis'
			ContentTop={() => <ProgressHeader></ProgressHeader>}
			sidebarContent={
				<>
					<strong>Additional Investment Costs</strong>
					<p>
						Please enter the additional investment costs required to succeed in value-based contracts.
						This might include investing in HIPAA compliant care navigation platforms, program
						administrators or managers, data analytics tools or staff, or creating incentivized benefits
						packages.
					</p>
					<p>Consider repurposing analysts or utilizing technology already available.</p>
					<strong>Cost Reductions</strong>
					<p>
						If you have made improvements and reduced costs you can include those here by including a “-”
						in front of the dollar value.
					</p>
				</>
			}
		>
			{!loading && (
				<>
					<p className='h2'>Future Costs</p>
					<h2 className='h1'>
						Enter any <span className='underline'>future</span> additional investment costs you’d like to
						factor in:
					</h2>
					<p className={css.headingOptionalNote}>(optional)</p>
					<br />
					<Formik
						initialValues={{
							assumedBenefitsPercentage: costs?.assumedBenefitsPercentage || 31,
							futureItemsOther: costs?.futureItemsOther || [
								{
									name: 'Other EMR capabilities, e.g., virtual/telehealth',
									costs: '',
									isFixed: true
								},
								{
									name: 'Embedded decision support',
									costs: '',
									isFixed: true
								},
								{
									name: 'Data analytics',
									costs: '',
									isFixed: true
								},
								{
									name: 'Align physician compensation plan',
									costs: '',
									isFixed: true
								},
								{
									name: 'Other technology connectivity capabilities',
									costs: '',
									isFixed: true
								},
								{
									name: 'Other',
									costs: ''
								}
							],
							totalFutureOtherCosts: ''
						}}
						validateOnChange={false}
						validateOnBlur={true}
						onSubmit={async data => {
							setIsSubmitting(true);
							await updateCosts(removeFrontendIdFromData(data, 'futureItemsOther'));
						}}
					>
						{props => (
							<CostsForm isSubmitting={isSubmitting} assessmentId={assessmentId} {...props}></CostsForm>
						)}
					</Formik>
				</>
			)}
		</PageFrame>
	);
};
interface IValues {
	assumedBenefitsPercentage: number;
	futureItemsOther: {
		uniqueId?: string;
		name: string;
		costs: number;
		isFixed?: boolean;
	}[];
	totalFutureOtherCosts: number;
}

const CostsForm = (props: any) => {
	const { isSubmitting } = props;
	const { values, setFieldValue } = useFormikContext<IValues>();
	useEffect(() => {
		let temptotalFutureOtherCosts = 0;
		values.futureItemsOther.forEach(item => {
			temptotalFutureOtherCosts += item.costs || 0;
		});
		if (temptotalFutureOtherCosts !== values.totalFutureOtherCosts) {
			setFieldValue('totalFutureOtherCosts', temptotalFutureOtherCosts);
		}

		// eslint-disable-next-line
	}, [values]);
	const fixedItemTooltips = [
		'EMR functionality costs to add patient engagement functions, e.g., web portal, virtual visit technology',
		'Costs to add point of care functionality for caregivers that assist in ordering appropriate testing or guidance for best practice treatments.',
		'Costs of investing in at-risk population analytics to assess timely cost and quality performance to guide necessary care adjustments',
		'Account for extra costs related to change in physician compensation plans that are necessary when shifting to value-based care',
		'Any other costs that are incurred as a result of being able to more effectively manage at-risk populations, e.g., connectivity to home health interactions/records'
	];
	return (
		<Form>
			{' '}
			<Section>
				<h1 className={'h4'}>Future Costs</h1>
				<FieldArray name='futureItemsOther'>
					{({ remove, push }) => (
						<>
							<div className='tableWrapper'>
								<table cellPadding={0} cellSpacing={0} className={css.costItems}>
									<tbody>
										<tr className={css.costItemRow}>
											<th>
												<div className='screenReaderText'>Item Name</div>
											</th>
											<th>Cost</th>
											<th>
												<div className='screenReaderText'>Remove Item</div>
											</th>
										</tr>
										{values.futureItemsOther.length > 0 &&
											values.futureItemsOther.map((item, index) => (
												<tr className={css.costItemRow} key={index}>
													<td className={css.large}>
														{item.isFixed ? (
															<div className={css.labelWithTooltip}>
																<strong className={css.inputLabelFixed}>{item.name}</strong>
																<Icon
																	className={css.icon}
																	type={'Info'}
																	size='small'
																	color='blueLight'
																	tooltip={fixedItemTooltips[index]}
																></Icon>
															</div>
														) : (
															<Field
																name={`futureItemsOther.${index}.name`}
																placeholder={'Enter name'}
																type='text'
																id={item.uniqueId}
															></Field>
														)}{' '}
													</td>
													<td>
														<Field
															name={`futureItemsOther.${index}.costs`}
															placeholder={'Enter cost ($)'}
															step='1'
															type='number'
														></Field>
													</td>

													<td className={classes(css.buttonWrap, css.smallest)}>
														{!item.isFixed && (
															<Button
																type='control'
																size='small'
																aria-label='Remove row'
																iconRight={{ type: 'Cancel', color: 'blueLight', size: 'large' }}
																onClick={event => {
																	event.preventDefault();
																	remove(index);
																}}
																htmlType='button'
															></Button>
														)}
													</td>
												</tr>
											))}
									</tbody>
								</table>
							</div>

							<Button
								type='link'
								iconLeft={{ type: 'Plus', color: 'green' }}
								onClick={event => {
									const uniqueId = getUniqueId();
									event.preventDefault();
									push({ uniqueId, name: '', costs: '' });
									focusOnId(uniqueId);
								}}
								htmlType='button'
							>
								Add Item
							</Button>
						</>
					)}
				</FieldArray>

				<ButtonPagination>
					<Link type='secondary' to={'/assessment/' + props.assessmentId + '/costs/future-team'}>
						Back
					</Link>
					<Button type='primary' loadingLabel='Saving' htmlType='submit' isLoading={isSubmitting}>
						Next
					</Button>
				</ButtonPagination>
			</Section>
		</Form>
	);
};
