import React, { useEffect, useRef } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'components/Link';
import { Icon } from 'components/Icon';
import classes from 'classnames';
import css from './PageFrame.module.scss';
import { Breadcrumbs, IBreadcrumbsProps } from 'components/Breadcrumbs';
import { Container } from 'components/Container/Container';
import { Header } from 'components/Header/Header';

interface IPageFrame {
	title: string;
	showHeading?: boolean;
	description?: string;
	backLocation?: { to: string; label: string };
	breadcrumbs?: IBreadcrumbsProps;
	ContentTop?: React.FC;
	ContentTopRight?: React.FC;
	classNames?: {
		pageFrame?: string;
		pageFrameTop?: string;
		contentTopRight?: string;
		title?: string;
	};
	sidebarContent?: React.ReactElement;
	fullWidth?: boolean;
	HTMLDescription?: string;
}

export const PageFrame: React.FC<IPageFrame> = props => {
	const {
		title,
		showHeading = false,
		description,
		children,
		backLocation,
		ContentTopRight,
		ContentTop,
		classNames,
		breadcrumbs,
		sidebarContent,
		fullWidth = false,
		HTMLDescription
	} = props;
	const titleRef = useRef<HTMLHeadingElement>();
	const contentRef = useRef<HTMLHeadingElement>();
	useEffect(() => {
		if (titleRef && titleRef.current) {
			titleRef.current.focus();
		} else if (contentRef && contentRef.current) {
			contentRef.current.focus();
		}
	}, []);

	const Content = () => (
		<>
			{breadcrumbs && <Breadcrumbs className={css.breadcrumbs} {...breadcrumbs}></Breadcrumbs>}
			<Helmet>
				<title>{title && `${title} | `}Volume-to-Value Forecaster</title>
				{HTMLDescription && <meta name='description' content={HTMLDescription} />}
			</Helmet>
			{(ContentTopRight || backLocation || showHeading || description) && (
				<div className={classes(css.pageFrameTop, classNames?.pageFrameTop)}>
					<div className={css.pageFrameTopInner}>
						{backLocation && (
							<nav>
								<Link to={backLocation.to} className={css.backLocation}>
									<Icon
										type='CaretLeft'
										color='black'
										size='smallest'
										className={css.backLocationIcon}
									></Icon>
									{backLocation.label}
								</Link>
							</nav>
						)}
						{showHeading && <h1 ref={titleRef}>{title}</h1>}
						{description && <p className='largeParagraph'>{description}</p>}
					</div>
					{ContentTopRight && (
						<div className={classes(css.contentTopRight, classNames?.contentTopRight)}>
							<ContentTopRight />
						</div>
					)}
				</div>
			)}
			<div ref={contentRef} className={css.content}>
				{children}
			</div>
		</>
	);
	return (
		<>
			<Header></Header>

			<main
				className={classes(css.pageFrame, sidebarContent && css.hasSidebar, classNames?.pageFrameTop)}
			>
				<div className={css.mainContent}>
					{ContentTop && <ContentTop></ContentTop>}
					{!fullWidth ? (
						<Container className={css.container}>
							<Content></Content>
						</Container>
					) : (
						<Content></Content>
					)}
				</div>
				{sidebarContent && (
					<aside className={css.helpSidebar}>
						<div className={css.inner}>
							<h2 className={classes(css.learnMore, 'h4')}>Learn More</h2>
							{sidebarContent}
						</div>
						<div className={css.sidebarBottom}>
							<h2 className='h4'>Need Help?</h2>
							<p>Get help from our experts to complete your assessment</p>
							<Link type='link' external to='mailto:info@fronthealth.com'>
								Contact Us
							</Link>
						</div>
					</aside>
				)}
			</main>
			<footer className={css.footer}>
				<div className={css.copyright}>&copy; 2021 Volume-to-Value Forecaster</div>
			</footer>
		</>
	);
};
