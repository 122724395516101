import { Button } from 'components/Button/Button';
import { PageFrame } from 'components/PageFrame/PageFrame';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { Field, FieldArray, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import css from './RevenueView.module.scss';
import { useHistory, useParams } from 'react-router';
import { Link } from 'components/Link/Link';
import { ButtonPagination } from 'components/ButtonPagination/ButtonPagination';
import { ProgressHeader } from 'components/ProgressHeader/ProgressHeader';
import classes from 'classnames';
import { Icon } from 'components/Icon/Icon';
import { Alert } from 'components/Alert/Alert';
import { getUniqueId } from 'common/utils/getUniqueId';
import { focusOnId } from 'common/utils/focusOnId';

export const RevenueStepPayersView = () => {
	// @ts-ignore
	const { assessmentId } = useParams();
	const firestore = firebase.firestore();

	const revenueDocument = firestore.collection('revenue').doc(assessmentId);
	const [revenue, loading] = useDocumentData(revenueDocument);

	const history = useHistory();

	const contractTypeOptions = [
		{ id: 'Commercial', label: 'Commercial' },
		{ id: 'Medicare Advantage', label: 'Medicare Advantage' },
		{ id: 'Medicaid Managed Care', label: 'Medicaid Managed Care' },
		{ id: 'Medicare', label: 'Medicare' },
		{ id: 'Medicaid', label: 'Medicaid' },
		{ id: 'Other', label: 'Other' }
	];
	const contractTypes = revenue?.contractTypes?.sort((a: string, b: string) =>
		contractTypeOptions.findIndex(contractType => a === contractType.id) >
		contractTypeOptions.findIndex(contractType => b === contractType.id)
			? 1
			: -1
	);

	const updateRevenue = async (data: any) => {
		data.payers.sort((a: any, b: any) =>
			a.contractType === b.contractType
				? 0
				: contractTypeOptions.findIndex(contractType => a.contractType === contractType.id) >
				  contractTypeOptions.findIndex(contractType => b.contractType === contractType.id)
				? 1
				: -1
		);

		try {
			await firestore
				.collection('revenue')
				.doc(assessmentId)
				.set(
					{
						...data,
						updatedAt: firebase.firestore.FieldValue.serverTimestamp()
					},
					{ merge: true }
				)
				.then(() => {
					history.push('/assessment/' + assessmentId + '/revenue/utilization');
				});
		} catch (error) {
			throw new Error(error);
		}
	};
	const [isSubmitting, setIsSubmitting] = useState(false);
	const payers = revenue?.payers || [];
	contractTypes?.forEach((contractType: any, contractTypeIndex: any) => {
		if (!payers || payers.filter((payer: any) => payer.contractType === contractType).length <= 0) {
			payers.push({
				name: contractType === 'Medicare' ? 'Medicare Fee-for-Service Payer' : '',
				contractType
			});
		}
	});

	payers?.forEach((payer: any, index: any) => {
		if (
			revenue?.contractTypes?.filter((contractType: any) => payer.contractType === contractType)
				.length <= 0
		) {
			payers.splice(index, 1);
		}
	});
	const validate = (values: any, props: any /* only available when using withFormik */) => {
		const errors = {};

		if (values.payers.length <= 0) {
			// @ts-ignore
			errors.top = 'Required';
		}

		return errors;
	};
	return (
		<>
			<PageFrame
				title='Revenue Analysis'
				ContentTop={() => <ProgressHeader></ProgressHeader>}
				sidebarContent={
					<>
						<strong>Payers</strong>
						<p>Please include all your contract payer information here.</p>
					</>
				}
			>
				{!loading && (
					<Formik
						initialValues={{
							payers: payers
						}}
						validateOnChange={false}
						validateOnBlur={false}
						onSubmit={async data => {
							setIsSubmitting(true);
							await updateRevenue(data);
						}}
						validate={validate as any}
					>
						{props => {
							return (
								<>
									<Form>
										<p className='h2'>Payers</p>

										{(props.errors as any).top && (
											<>
												<br />
												<Alert type='danger'>You must add at least one payer</Alert>
											</>
										)}
										{revenue?.contractTypes.indexOf('Medicare') >= 0 &&
										revenue?.contractTypes.length === 1 ? (
											<h2 className={classes('h1', css.headingNoMargin)}>
												Looks like you selected
												{revenue.contractTypes.map((item: any, index: number) => (
													<>
														{index > 0 && ' and'} {item}
													</>
												))}{' '}
												so we've pre-filled your payers please continue to the next step.
											</h2>
										) : (
											<h2 className='h1'>Now, let’s break down your payers:</h2>
										)}
										<br />
										{revenue?.contractTypes?.map((contractType: any, index: any) => {
											if (contractType === 'Medicare') {
												return (
													<div key={index} className={css.payer}>
														<h3>{contractType}</h3>
														<div className={css.staticPayerName}>Medicare Fee-for-Service Payer</div>
													</div>
												);
											} else {
												return (
													<div key={index} className={css.payer}>
														<h3>{contractType}</h3>
														<FieldArray name='payers'>
															{({ remove, push }) => (
																<>
																	<div className='tableWrapper'>
																		<table cellPadding={0} cellSpacing={0} className={css.costItems}>
																			<tbody>
																				<tr className={css.costItemRow}>
																					<th>
																						Payer Name{' '}
																						<Icon
																							className={css.icon}
																							type={'Info'}
																							size='small'
																							color='blueLight'
																							tooltip='Use payer naming to easily distinguish between all unique contracts'
																						></Icon>
																					</th>
																					<th>
																						<div className='screenReaderText'>Remove Item</div>
																					</th>
																				</tr>
																				{props.values?.payers?.length > 0 &&
																					props.values.payers.map((item: any, index: any) => {
																						if (item.contractType !== contractType) {
																							return <React.Fragment key={index}></React.Fragment>;
																						} else {
																							return (
																								<tr className={css.costItemRow} key={index}>
																									<td className={css.large}>
																										<div className={css.inputWithTooltip}>
																											<Field
																												name={`payers.${index}.name`}
																												placeholder={'Enter name'}
																												type='text'
																												id={item.uniqueId}
																											></Field>{' '}
																										</div>
																									</td>

																									<td className={classes(css.buttonWrap, css.smallest)}>
																										<Button
																											type='control'
																											size='small'
																											aria-label='Remove row'
																											iconRight={{
																												type: 'Cancel',
																												color: 'blueLight',
																												size: 'large'
																											}}
																											onClick={event => {
																												event.preventDefault();
																												remove(index);
																											}}
																											htmlType='button'
																										></Button>
																									</td>
																								</tr>
																							);
																						}
																					})}
																			</tbody>
																		</table>
																	</div>

																	<Button
																		type='link'
																		iconLeft={{ type: 'Plus', color: 'green' }}
																		onClick={event => {
																			const uniqueId = getUniqueId();
																			event.preventDefault();
																			push({ uniqueId, name: '', contractType });
																			focusOnId(uniqueId);
																		}}
																		htmlType='button'
																	>
																		Add Payer
																	</Button>
																</>
															)}
														</FieldArray>
													</div>
												);
											}
										})}
										<ButtonPagination>
											<Link type='secondary' to={'/assessment/' + assessmentId + '/revenue'}>
												Back
											</Link>
											{isSubmitting}
											<Button type='primary' htmlType='submit' isLoading={isSubmitting}>
												Next
											</Button>
										</ButtonPagination>
									</Form>
								</>
							);
						}}
					</Formik>
				)}
			</PageFrame>
		</>
	);
};
