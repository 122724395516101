import React from 'react';
import css from './BadgeIcon.module.scss';
import classes from 'classnames';
import { Badge, IBadgeProps } from 'components/Badge';
import { Icon, IIconProps } from 'components/Icon';
import { RemoveProp } from 'common/types/TypeHelpers';
import { TButtonTypes } from 'common/types/TButtonTypes';

interface IBadgeIconProps {
	className?: string;
	icon: RemoveProp<IIconProps, 'size'>;
	badge?: IBadgeProps;
	context?: TButtonTypes;
}

export const BadgeIcon: React.FC<IBadgeIconProps> = props => {
	const { className, children, icon, badge, context } = props;
	return (
		<span className={classes(className, css.badgeIcon)}>
			<Icon
				{...icon}
				size='large'
				context={context}
				className={classes(icon?.className, css.icon)}
			></Icon>
			<Badge {...badge} className={classes(badge?.className, css.badge)}>
				<span aria-hidden='true'>{children}</span>
			</Badge>
		</span>
	);
};
