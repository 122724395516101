import React from "react";
import css from "./Alert.module.scss";
import classes from "classnames";
import { Button } from "components/Button/Button";
import { TAlertTypes } from "common/types/TAlertTypes";

export interface IAlertProps {
  className?: string;
  type?: TAlertTypes;
  isDismissible?: boolean;
  setIsShown?: React.Dispatch<React.SetStateAction<boolean>>;
  accessibilityRole?: "alert" | "status";
}

export const Alert: React.FC<IAlertProps> = (props) => {
  const {
    type = "info",
    className,
    children,
    isDismissible = false,
    setIsShown,
    accessibilityRole = "alert",
  } = props;

  return (
    <div
      className={classes(css.alert, css[type], className)}
      role={accessibilityRole}
    >
      <span className={css.text}>{children}</span>
      {isDismissible && (
        <Button
          type="link"
          onClick={() => setIsShown(false)}
          iconLeft={{
            type: "Cancel",
            color: type === "warning" ? "black" : "white",
          }}
          className={css.dismissMessage}
        ></Button>
      )}
    </div>
  );
};
