import { Button } from 'components/Button/Button';
import { PageFrame } from 'components/PageFrame/PageFrame';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import css from './RevenueView.module.scss';
import { useHistory, useParams } from 'react-router';
import { Link } from 'components/Link/Link';
import { ButtonPagination } from 'components/ButtonPagination/ButtonPagination';
import { ProgressHeader } from 'components/ProgressHeader/ProgressHeader';
import { CheckboxGroup } from 'components/Form/CheckboxGroup/CheckboxGroup';
import { Alert } from 'components/Alert/Alert';
// import classes from 'classnames';

export const RevenueStep0View = () => {
	// @ts-ignore
	const { assessmentId } = useParams();
	const firestore = firebase.firestore();

	const revenueDocument = firestore.collection('revenue').doc(assessmentId);
	const [revenue, loading] = useDocumentData(revenueDocument);

	const history = useHistory();

	const updateRevenue = async (data: any) => {
		data.contractTypes = data.contractTypes.sort((a: string, b: string) =>
			contractTypeOptions.findIndex(contractType => a === contractType.id) >
			contractTypeOptions.findIndex(contractType => b === contractType.id)
				? 1
				: -1
		);
		try {
			await firestore
				.collection('revenue')
				.doc(assessmentId)
				.set(
					{
						...data,
						updatedAt: firebase.firestore.FieldValue.serverTimestamp()
					},
					{ merge: true }
				)
				.then(() => {
					history.push('/assessment/' + assessmentId + '/revenue/payers');
				});
		} catch (error) {
			throw new Error(error);
		}
	};
	const [isSubmitting, setIsSubmitting] = useState(false);

	const contractTypeOptions = [
		{ id: 'Commercial', label: 'Commercial' },
		{ id: 'Medicare Advantage', label: 'Medicare Advantage' },
		{ id: 'Medicaid Managed Care', label: 'Medicaid Managed Care' },
		{ id: 'Medicare', label: 'Medicare' },
		{ id: 'Medicaid', label: 'Medicaid' },
		{ id: 'Other', label: 'Other' }
	];

	const validate = (values: any, props: any /* only available when using withFormik */) => {
		const errors = {};

		if (values.contractTypes.length <= 0) {
			// @ts-ignore
			errors.top = 'Required';
		}

		return errors;
	};
	return (
		<>
			<PageFrame
				title='Revenue Analysis'
				ContentTop={() => <ProgressHeader></ProgressHeader>}
				sidebarContent={
					<>
						<strong>
							Contract types (In this order: Commercial, Medicare Advantage, Medicaid Managed Care,
							Medicare, Medicaid)
						</strong>
						<p>
							It is important to understand your overall payer mix in when moving to value-based care
							contracting.
						</p>
						<p>
							At-risk methodology is often similar in payer arrangements within the same contract type,
							e.g., Medicare Advantage, Commercial.
						</p>
					</>
				}
			>
				{!loading && (
					<Formik
						initialValues={{
							contractTypes: revenue?.contractTypes || []
						}}
						validateOnChange={false}
						validateOnBlur={false}
						onSubmit={async data => {
							setIsSubmitting(true);
							await updateRevenue(data);
						}}
						validate={validate as any}
					>
						{props => {
							return (
								<Form>
									{' '}
									<p className='h2'>Let’s learn about your current contract types</p>
									<h2 className='h1'>Select all current contracts that apply to you:</h2>
									{(props.errors as any).top && (
										<>
											<br />
											<Alert type='danger'>You must add at least one contract type</Alert>
										</>
									)}
									<br />
									<CheckboxGroup
										label='Contract Types'
										name='contractTypes'
										styleType='button'
										className={css.checkboxButtonGroup}
										items={contractTypeOptions.map(item => ({
											label: item.label,
											name: 'contractTypes',
											value: item.id,
											checked: props.values.contractTypes.includes(item.id)
										}))}
										formikProps={props}
									></CheckboxGroup>
									<ButtonPagination>
										<Link type='secondary' to={'/assessment/' + assessmentId}>
											Back
										</Link>
										{isSubmitting}
										<Button type='primary' htmlType='submit' isLoading={isSubmitting}>
											Next
										</Button>
									</ButtonPagination>
								</Form>
							);
						}}
					</Formik>
				)}
			</PageFrame>
		</>
	);
};
