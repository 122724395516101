import { PageFrame } from 'components/PageFrame/PageFrame';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import React from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import css from './RevenueView.module.scss';
import { useParams } from 'react-router';
import { Link } from 'components/Link/Link';
import { ButtonPagination } from 'components/ButtonPagination/ButtonPagination';
import { formatAsUSD } from 'common/utils/formatAsUSD';
import classes from 'classnames';
import { Container } from 'components/Container/Container';
import { Icon } from 'components/Icon/Icon';
import { ProgressHeader } from 'components/ProgressHeader/ProgressHeader';
import { Tabs } from 'components/Tabs/Tabs';
import { Section } from 'components/Section/Section';

export const RevenueSummaryView = () => {
	// @ts-ignore
	const { assessmentId } = useParams();
	const firestore = firebase.firestore();

	const revenueDocument = firestore.collection('revenue').doc(assessmentId);
	const [revenue, loading] = useDocumentData(revenueDocument);

	let totalLives = 0;
	let ffsNetRevenue = 0;
	let valueBasedNetRevenue = 0;
	let totalPotentialChangeInRevenue = 0;

	revenue?.payers?.forEach((item: any) => {
		totalLives += item.memberLives || 0;
		ffsNetRevenue +=
			(item.netFFSRevenueInpatient || 0) +
			(item.netFFSRevenueOutpatient || 0) +
			(item.netFFSRevenueProfessional || 0);
		valueBasedNetRevenue += item.valueBasedNetRevenue || 0;

		item.utilizations?.forEach((utilization: any) => {
			totalPotentialChangeInRevenue +=
				(Math.round(utilization.futureUtilizationPer1000 / 1000
					* item.memberLives) -
				Math.round(Math.round(utilization.currentUtilizationsAmount / item.memberLives * 1000) / 1000
					* item.memberLives)) *
				(utilization.revenue / utilization.currentUtilizationsAmount) || 0;
		});
	});
	totalPotentialChangeInRevenue += valueBasedNetRevenue || 0;

	const totalNetRevenue = (valueBasedNetRevenue || 0) + (ffsNetRevenue || 0);
	const pmpmRevenue = totalNetRevenue / totalLives / 12;

	const newLivesToBreakEven =
		totalPotentialChangeInRevenue > 0 ? 0 : (totalPotentialChangeInRevenue * -1) / (pmpmRevenue * 12);

	const TotalsTable = () => (
		<>
			<h3 className='h2'>Revenue Overview</h3>
			<div className='tableWrapper'>
				<table className={css.totalsTable}>
					<thead>
						<tr>
							<th colSpan={6}>
								<h2 className={css.livesNeededHeading}>
									<Icon type='Users' size='large' color='white' className={css.icon}></Icon>
									Number of new lives needed to break-even:{' '}
									<strong className={css.newLivestoBreakEven}>
										{newLivesToBreakEven ? newLivesToBreakEven.toFixed(0) : 0}
									</strong>
								</h2>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<h6 className='h7'>Total Lives</h6>
								<div className={css.amount}>{totalLives || 0}</div>
							</td>
							<td>
								<h6 className='h7'>FFS Net Revenue</h6>
								<div className={css.amount}>{formatAsUSD(ffsNetRevenue || 0)}</div>
							</td>
							<td>
								<h6 className='h7'>Value Based Net Revenue</h6>
								<div className={css.amount}>{formatAsUSD(valueBasedNetRevenue || 0)}</div>
							</td>
							<td>
								<h6 className='h7'>Total Net Revenue</h6>
								<div className={css.amount}>{formatAsUSD(totalNetRevenue || 0)}</div>
							</td>
							<td>
								<h6 className='h7'>PMPM Revenue</h6>
								<div className={css.amount}>{formatAsUSD(pmpmRevenue || 0)}</div>
							</td>
							<td>
								<h6 className='h7'>Total Potential Change in Revenue</h6>
								<div className={css.amount}>{formatAsUSD(totalPotentialChangeInRevenue || 0)}</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</>
	);

	const tabs = [
		{
			label: 'All',
			location: '/assessment/' + assessmentId + '/revenue/summary/',
			route: '/assessment/' + assessmentId + '/revenue/summary/',
			component: () => (
				<div className={css.summaryTabContent}>
					<TotalsTable></TotalsTable>
					<h3 className='h2'>Payer Type Overview</h3>
					{revenue?.contractTypes?.map((contractType: any, index: number) => {
						const payers = revenue?.payers?.filter((payer: any) => payer.contractType === contractType);
						let tempTotalLives = 0;
						let tempFfsNetRevenue = 0;
						let tempValueBasedNetRevenue = 0;
						let tempTotalPotentialChangeInRevenue = 0;

						payers.forEach((payer: any) => {
							tempTotalLives += payer.memberLives || 0;
							tempFfsNetRevenue +=
								(payer.netFFSRevenueInpatient || 0) +
								(payer.netFFSRevenueOutpatient || 0) +
								(payer.netFFSRevenueProfessional || 0);
							tempValueBasedNetRevenue += payer.valueBasedNetRevenue || 0;

							payer.utilizations?.forEach((utilization: any) => {
								
								tempTotalPotentialChangeInRevenue +=
								(Math.round(utilization.futureUtilizationPer1000 / 1000
									* payer.memberLives) -
								Math.round(Math.round(utilization.currentUtilizationsAmount / payer.memberLives * 1000) / 1000
									* payer.memberLives)) *
								(utilization.revenue / utilization.currentUtilizationsAmount) || 0;

							});
						});
						tempTotalPotentialChangeInRevenue += tempValueBasedNetRevenue || 0;
						const tempTotalNetRevenue = (tempValueBasedNetRevenue || 0) + (tempFfsNetRevenue || 0);
						const tempPmpmRevenue = tempTotalNetRevenue / tempTotalLives / 12;
						const tempPercentOfTotalRevenue = tempTotalNetRevenue / totalNetRevenue;
						return (
							<div key={index}>
								<h4 className={classes(css.uppercase, 'h5')}>{contractType} Break Down</h4>
								<div className='tableWrapper'>
									<table className={css.totalsTable}>
										<thead>
											<tr>
												<th>
													<div className={css.thInner}>
														<div className={css.thLabel}>Total Lives</div>
														<Icon
															className={css.icon}
															type={'Info'}
															size='small'
															color='blueLight'
															tooltip='The number of attributed or assigned members in the contract.'
														></Icon>
													</div>
												</th>
												<th>
													<div className={css.thInner}>
														<div className={css.thLabel}>FFS net revenue</div>
														<Icon
															className={css.icon}
															type={'Info'}
															size='small'
															color='blueLight'
															tooltip='Total Net Fee-For-Service (FFS) = Inpatient + Outpatient + Professional'
														></Icon>
													</div>
												</th>
												<th>
													<div className={css.thInner}>
														<div className={css.thLabel}>Value Based Net revenue</div>
														<Icon
															className={css.icon}
															type={'Info'}
															size='small'
															color='blueLight'
															tooltip='Revenue from value-based contract performance not included in previous revenue columns.'
														></Icon>
													</div>
												</th>
												<th>
													<div className={css.thInner}>
														<div className={css.thLabel}>TOTAL Net revenue</div>
														<Icon
															className={css.icon}
															type={'Info'}
															size='small'
															color='blueLight'
															tooltip='Revenue is the total annual amount paid for the event type for the payer.'
														></Icon>
													</div>
												</th>
												<th>
													<div className={css.thInner}>
														<div className={css.thLabel}>% of Total revenue</div>
														<Icon
															className={css.icon}
															type={'Info'}
															size='small'
															color='blueLight'
															tooltip='This is the net revenue of this contract compared to the total net revenue of the organization'
														></Icon>
													</div>
												</th>
												<th>
													<div className={css.thInner}>
														<div className={css.thLabel}>pmpM revenue</div>
														<Icon
															className={css.icon}
															type={'Info'}
															size='small'
															color='blueLight'
															tooltip='This is the monthly net revenue per each individual member per month'
														></Icon>
													</div>
												</th>
												<th className={css.totalColumn}>
													<div className={css.thInner}>
														<div className={css.thLabel}>Total Potential change in Revenue</div>
														<Icon
															className={css.icon}
															type={'Info'}
															size='small'
															color='blueLight'
															tooltip='This is the change in fee-for-service revenue anticipated due to the potential decrease in high-cost utilizations.'
														></Icon>
													</div>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>{tempTotalLives || 0}</td>
												<td>{formatAsUSD(tempFfsNetRevenue || 0)}</td>
												<td>{formatAsUSD(tempValueBasedNetRevenue || 0)}</td>
												<td>{formatAsUSD(tempTotalNetRevenue || 0)}</td>
												<td>{((tempPercentOfTotalRevenue || 0) * 100).toFixed(2) + '%'}</td>
												<td>{formatAsUSD(tempPmpmRevenue || 0)}</td>
												<td className={css.totalColumn}>
													{formatAsUSD(tempTotalPotentialChangeInRevenue || 0)}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						);
					})}
				</div>
			)
		}
	];
	revenue?.contractTypes?.forEach((contractType: any) => {
		const startingState = {totalLives:0, ffsNetRevenue:0, valueBasedNetRevenue:0, netRevenue:0, pmpmRevenue: 0, totalPotentialChangeInRevenue: 0}
		const tabSummaryData = revenue?.payers?.filter((payer: any) => (payer.contractType === contractType))
		.reduce(
			(previousValue: typeof startingState, payer: any) => {
				const tabTotalLives = payer.memberLives || 0;
				const tabFfsNetRevenue = 
					(payer.netFFSRevenueInpatient || 0) +
					(payer.netFFSRevenueOutpatient || 0) +
					(payer.netFFSRevenueProfessional || 0);
				const tabValueBasedNetRevenue = payer.valueBasedNetRevenue || 0;

				let tabTotalPotentialChangeInRevenue = 0;
				payer.utilizations?.forEach((utilization: any) => {
					tabTotalPotentialChangeInRevenue +=
						(Math.round(utilization.futureUtilizationPer1000 / 1000
							* payer.memberLives) -
						Math.round(Math.round(utilization.currentUtilizationsAmount / payer.memberLives * 1000) / 1000
							* payer.memberLives)) *
						(utilization.revenue / utilization.currentUtilizationsAmount) || 0;
				});
				tabTotalPotentialChangeInRevenue += tabValueBasedNetRevenue || 0;
				
				const tabNetRevenue = (tabValueBasedNetRevenue || 0) + (tabFfsNetRevenue || 0);
				const tabPmpmRevenue = tabNetRevenue / tabTotalLives / 12;
				return {
					totalLives: previousValue.totalLives + tabTotalLives,
					ffsNetRevenue: previousValue.ffsNetRevenue + tabFfsNetRevenue,
					valueBasedNetRevenue: previousValue.valueBasedNetRevenue + tabValueBasedNetRevenue,
					netRevenue: previousValue.netRevenue + tabNetRevenue,
					pmpmRevenue: previousValue.pmpmRevenue + tabPmpmRevenue,
					totalPotentialChangeInRevenue: previousValue.totalPotentialChangeInRevenue + tabTotalPotentialChangeInRevenue
				}
			}
			, startingState
		)
		const tabNewLivesToBreakEven = tabSummaryData.totalPotentialChangeInRevenue > 0 ? 0 : (tabSummaryData.totalPotentialChangeInRevenue * -1) / (tabSummaryData.pmpmRevenue * 12);

		tabs.push({
			label: contractType,
			location:
				'/assessment/' +
				assessmentId +
				'/revenue/summary/' +
				contractType.toLowerCase().replace(' ', '-'),
			route:
				'/assessment/' +
				assessmentId +
				'/revenue/summary/' +
				contractType.toLowerCase().replace(' ', '-'),
			component: () => (
				<div className={css.summaryTabContent}>
					<h3 className='h2'>Revenue Overview</h3>
					<div className='tableWrapper'>
						<table className={css.totalsTable}>
							<thead>
								<tr>
									<th colSpan={6}>
										<h2 className={css.livesNeededHeading}>
											<Icon type='Users' size='large' color='white' className={css.icon}></Icon>
											Number of new lives needed to break-even:{' '}
											<strong className={css.newLivestoBreakEven}>
												{tabNewLivesToBreakEven ? tabNewLivesToBreakEven.toFixed(0) : 0}
											</strong>
										</h2>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<h6 className='h7'>Total Lives</h6>
										<div className={css.amount}>{tabSummaryData.totalLives || 0}</div>
									</td>
									<td>
										<h6 className='h7'>FFS Net Revenue</h6>
										<div className={css.amount}>{formatAsUSD(tabSummaryData.ffsNetRevenue || 0)}</div>
									</td>
									<td>
										<h6 className='h7'>Value Based Net Revenue</h6>
										<div className={css.amount}>{formatAsUSD(tabSummaryData.valueBasedNetRevenue || 0)}</div>
									</td>
									<td>
										<h6 className='h7'>Total Net Revenue</h6>
										<div className={css.amount}>{formatAsUSD(tabSummaryData.netRevenue || 0)}</div>
									</td>
									<td>
										<h6 className='h7'>PMPM Revenue</h6>
										<div className={css.amount}>{formatAsUSD(tabSummaryData.pmpmRevenue || 0)}</div>
									</td>
									<td>
										<h6 className='h7'>Total Potential Change in Revenue</h6>
										<div className={css.amount}>{formatAsUSD(tabSummaryData.totalPotentialChangeInRevenue || 0)}</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<h2>Payer Type Overview</h2>
					{revenue?.payers?.map((payer: any, index: number) => {
						if (payer.contractType !== contractType) {
							return <></>;
						}
						let tempTotalLives = 0;
						let tempFfsNetRevenue = 0;
						let tempValueBasedNetRevenue = 0;
						let tempTotalPotentialChangeInRevenue = 0;

						tempTotalLives += payer.memberLives || 0;
						tempFfsNetRevenue +=
							(payer.netFFSRevenueInpatient || 0) +
							(payer.netFFSRevenueOutpatient || 0) +
							(payer.netFFSRevenueProfessional || 0);
						tempValueBasedNetRevenue += payer.valueBasedNetRevenue || 0;

						payer.utilizations?.forEach((utilization: any) => {
							tempTotalPotentialChangeInRevenue +=
								(Math.round(utilization.futureUtilizationPer1000 / 1000
									* payer.memberLives) -
								Math.round(Math.round(utilization.currentUtilizationsAmount / payer.memberLives * 1000) / 1000
									* payer.memberLives)) *
								(utilization.revenue / utilization.currentUtilizationsAmount) || 0;
						});

						tempTotalPotentialChangeInRevenue += tempValueBasedNetRevenue || 0;

						const tempTotalNetRevenue = (tempValueBasedNetRevenue || 0) + (tempFfsNetRevenue || 0);
						const tempPmpmRevenue = tempTotalNetRevenue / tempTotalLives / 12;
						const tempPercentOfTotalRevenue = tempTotalNetRevenue / totalNetRevenue;
						return (
							<>
								<h5 className={css.uppercase}>{payer.name} Break Down</h5>
								<div className='tableWrapper'>
									<table className={css.totalsTable}>
										<thead>
											<tr>
												<th>
													<div className={css.thInner}>
														<div className={css.thLabel}>Total Lives</div>
														<Icon
															className={css.icon}
															type={'Info'}
															size='small'
															color='blueLight'
															tooltip='The number of attributed or assigned members in the contract.'
														></Icon>
													</div>
												</th>
												<th>
													<div className={css.thInner}>
														<div className={css.thLabel}>FFS net revenue</div>
														<Icon
															className={css.icon}
															type={'Info'}
															size='small'
															color='blueLight'
															tooltip='Total Net Fee-For-Service (FFS) = Inpatient + Outpatient + Professional'
														></Icon>
													</div>
												</th>
												<th>
													<div className={css.thInner}>
														<div className={css.thLabel}>Value Based Net revenue</div>
														<Icon
															className={css.icon}
															type={'Info'}
															size='small'
															color='blueLight'
															tooltip='Revenue from value-based contract performance not included in previous revenue columns.'
														></Icon>
													</div>
												</th>
												<th>
													<div className={css.thInner}>
														<div className={css.thLabel}>TOTAL Net revenue</div>
														<Icon
															className={css.icon}
															type={'Info'}
															size='small'
															color='blueLight'
															tooltip='Revenue is the total annual amount paid for the event type for the payer. '
														></Icon>
													</div>
												</th>
												<th>
													<div className={css.thInner}>
														<div className={css.thLabel}>% of Total revenue</div>
														<Icon
															className={css.icon}
															type={'Info'}
															size='small'
															color='blueLight'
															tooltip='This is the net revenue of this contract compared to the total net revenue of the organization'
														></Icon>
													</div>
												</th>
												<th>
													<div className={css.thInner}>
														<div className={css.thLabel}>pmpM revenue</div>
														<Icon
															className={css.icon}
															type={'Info'}
															size='small'
															color='blueLight'
															tooltip='This is the monthly net revenue per each individual member per month'
														></Icon>
													</div>
												</th>
												<th className={css.totalColumn}>
													<div className={css.thInner}>
														<div className={css.thLabel}>Total Potential change in Revenue</div>
														<Icon
															className={css.icon}
															type={'Info'}
															size='small'
															color='blueLight'
															tooltip='This is the change in fee-for-service revenue anticipated due to the potential decrease in high-cost utilizations.
                                                            '
														></Icon>
													</div>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr key={index}>
												<td>{tempTotalLives || 0}</td>
												<td>{formatAsUSD(tempFfsNetRevenue || 0)}</td>
												<td>{formatAsUSD(tempValueBasedNetRevenue || 0)}</td>
												<td>{formatAsUSD(tempTotalNetRevenue || 0)}</td>
												<td>{((tempPercentOfTotalRevenue || 0) * 100).toFixed(2) + '%'}</td>
												<td>{formatAsUSD(tempPmpmRevenue || 0)}</td>
												<td className={css.totalColumn}>
													{formatAsUSD(tempTotalPotentialChangeInRevenue || 0)}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</>
						);
					})}
				</div>
			)
		});
	});

	return (
		<PageFrame fullWidth title='Revenue Analysis' ContentTop={() => <ProgressHeader></ProgressHeader>}>
			{!loading && (
				<>
					<Container className={css.pageContainer}>
						<Section>
							<p className='h2'>You’ve completed your Revenue Analysis</p>
							<h2 className={classes('h1', css.headingNoMargin)}>View your high-level summary below:</h2>
							<br />
							<Tabs navigationItems={tabs}></Tabs>
						</Section>
						<ButtonPagination showInstructions={false}>
							<Link type='secondary' to={'/assessment/' + assessmentId + '/revenue/utilization'}>
								Back
							</Link>
							<Link type='primary' to={'/assessment/' + assessmentId}>
								Done
							</Link>
						</ButtonPagination>
					</Container>
				</>
			)}
		</PageFrame>
	);
};
