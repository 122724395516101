import React, { useEffect, useRef } from 'react';
import css from './PageNotFoundView.module.scss';
import { PageFrame } from 'components/PageFrame/PageFrame';
import { Section } from 'components/Section/Section';

interface IPageNotFoundView {
	isInAppFrame?: boolean;
}

export const PageNotFoundView: React.FC<IPageNotFoundView> = props => {
	const titleRef = useRef<HTMLHeadingElement>();
	useEffect(() => {
		titleRef.current.focus();
	}, []);

	return (
		<PageFrame title='Page not found'>
			<Section className={css.pageWrap}>
				<h1 className='h2' ref={titleRef} tabIndex={-1}>
					Page not found
				</h1>
				<p></p>
				<a href='/'>Go Back</a>
			</Section>
		</PageFrame>
	);
};
