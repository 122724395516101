import { Button } from 'components/Button/Button';
import { PageFrame } from 'components/PageFrame/PageFrame';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Link } from 'components/Link/Link';
import { useHistory, useParams } from 'react-router';
import { Table } from 'components/Table/Table';
import { Pagination } from 'components/Pagination/Pagination';
import css from './AssessmentsView.module.scss';
import { useModal } from 'components/Modal/ModalProvider';

export const AssessmentsView = () => {
	// @ts-ignore
	let { pageNumber } = useParams();
	pageNumber = parseFloat(pageNumber) || 1;

	const itemsPerPage = 10;

	const auth = firebase.auth();
	const firestore = firebase.firestore();

	const [user] = useAuthState(auth);

	const history = useHistory();

	const createAssessment = async () => {
		try {
			await firestore
				.collection('assessments')
				.add({
					uid: user.uid,
					name: 'Untitled Assessment',
					createdAt: firebase.firestore.FieldValue.serverTimestamp()
				})
				.then((docRef: any) => {
					history.push('/assessment/' + docRef.id);
				});
		} catch (error) {
			throw new Error(error);
		}
	};
	const { createModal, removeModal } = useModal();
	const getModalProps = (event: any, assessmentId: any) => ({
		sourceEvent: event,
		heading: 'Remove',
		Content: () => (
			<p>
				Are you sure you want to remove this assessment? Doing so will remove all data entered for this
				assessment.
			</p>
		),
		primaryButtonAction: () => {
			deleteAssessment(assessmentId);
			removeModal();
		}
	});

	const deleteAssessment = async (assessmentId: any) => {
		try {
			await firestore.collection('costs').doc(assessmentId).delete();
		} catch (error) {
			// Cost document doesn't exist
		}

		try {
			await firestore.collection('revenue').doc(assessmentId).delete();
		} catch (error) {
			// Revenue document doesn't exist
		}

		try {
			await firestore.collection('assessments').doc(assessmentId).delete();
		} catch (error) {
			throw new Error(error);
		}
	};

	const duplicateAssessment = async (assessmentId: any) => {
		try {
			const assessmentItem = assessments.filter(item => item.id === assessmentId);
			console.log('assessments', assessmentItem);
			await firestore
				.collection('assessments')
				.add({
					uid: user.uid,
					// @ts-ignore
					name: assessmentItem[0]?.name + ' (Copy)',
					createdAt: firebase.firestore.FieldValue.serverTimestamp()
				})
				.then(async (docRef: any) => {
					const costsDocument = firestore.collection('costs').doc(assessmentId);
					const costsData = await costsDocument
						.get()
						.then(doc => doc.exists && doc.data())
						.catch(error => {
							throw new Error(error);
						});

					if (costsData) {
						await firestore
							.collection('costs')
							.doc(docRef.id)
							.set({ ...costsData });
					}

					const revenueDocument = firestore.collection('revenue').doc(assessmentId);
					const revenueData = await revenueDocument
						.get()
						.then(doc => doc.exists && doc.data())
						.catch(error => {
							throw new Error(error);
						});

					if (revenueData) {
						await firestore
							.collection('revenue')
							.doc(docRef.id)
							.set({ ...revenueData });
					}
				});
		} catch (error) {
			throw new Error(error);
		}
	};

	const assessmentsCollection = firestore.collection('assessments').where('uid', '==', user.uid);
	const [assessments, loadingAssessments] = useCollectionData(assessmentsCollection, { idField: 'id' });

	if (assessments) {
		assessments.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
	}
	const assessmentsForCurrentPage = assessments?.slice(
		(pageNumber - 1) * itemsPerPage,
		pageNumber * itemsPerPage
	);

	return (
		<PageFrame
			title='My Assessments'
			showHeading={true}
			description='Start a new assessment or jump back into an existing one to view different scenarios and outcomes.'
			ContentTopRight={() => (
				<Button
					className={css.startNewButton}
					type='primary'
					htmlType='submit'
					onClick={createAssessment}
				>
					Start New Assessment
				</Button>
			)}
			sidebarContent={
				<>
					<strong>Assessments</strong>
					<p>
						This tool allows you to create different scenarios for planning and decision making purposes.
					</p>
					<p>
						Click “Start New Assessment” button to begin a new assessment or click on previously saved
						assessments to edit or continue the assessment.
					</p>
				</>
			}
		>
			{!loadingAssessments && assessmentsForCurrentPage && (
				<>
					{assessmentsForCurrentPage.length > 0 ? (
						<>
							<Table
								className={css.assessmentsTable}
								headers={[
									{
										label: 'Assessment Name',
										property: 'name',
										renderFunction: (data: any) => <Link to={'/assessment/' + data.id}>{data.name}</Link>
									},
									{
										label: 'Date Created',
										property: 'createdAt',
										renderFunction: (data: any) => {
											const date = data.createdAt ? (data?.createdAt?.toDate() as Date) : null;
											return <>{date?.toLocaleDateString() || new Date().toLocaleDateString()}</>;
										}
									},
									{
										label: 'Manage',
										property: 'id',
										renderFunction: (data: any) => (
											<div className={css.manageOptions}>
												<Link to={'/assessment/' + data.id}>Edit</Link>
												<span aria-hidden='true' className={css.separator}>
													|
												</span>
												<Button type='link' onClick={() => duplicateAssessment(data.id)}>
													Duplicate
												</Button>
												<span aria-hidden='true' className={css.separator}>
													|
												</span>
												<Button
													type='link'
													onClick={event => {
														createModal(getModalProps(event, data.id));
													}}
												>
													Remove
												</Button>
											</div>
										)
									}
								]}
								// @ts-ignore
								data={assessmentsForCurrentPage}
							></Table>
							<br />
							<Pagination
								itemAmount={assessments.length}
								itemsPerPage={itemsPerPage}
								currentPage={pageNumber}
								baseRoute={'/assessments/'}
							></Pagination>
						</>
					) : (
						<em>Your in progress and completed assessments will appear here. </em>
					)}
				</>
			)}
		</PageFrame>
	);
};
