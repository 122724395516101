import React from 'react';
import css from './RadioGroup.module.scss';
import classes from 'classnames';

interface IRadioGroup {
	className?: string;
}

export const RadioGroup: React.FC<IRadioGroup> = props => {
	const { children, className } = props;

	return <div className={classes(className, css.radioGroup)}>{children}</div>;
};
