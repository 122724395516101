import { PageFrame } from 'components/PageFrame/PageFrame';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import React from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import css from './AssessmentView.module.scss';
import { Link } from 'components/Link/Link';
import { formatAsUSD } from 'common/utils/formatAsUSD';
import { useParams } from 'react-router';
import { Form, Formik } from 'formik';
import { Button } from 'components/Button/Button';
import { Input } from 'components/Form/Input/Input';
import * as yup from 'yup';
import { useMessage } from 'components/Message/MessageProvider';
import { Table } from 'components/Table/Table';
import classes from 'classnames';
import { useModal } from 'components/Modal/ModalProvider';

export const AssessmentView = () => {
	// @ts-ignore
	const { assessmentId } = useParams();
	const firestore = firebase.firestore();

	const assessmentDocument = firestore.collection('assessments').doc(assessmentId);
	const [assessment, loadingAssessment] = useDocumentData(assessmentDocument);

	const costsDocument = firestore.collection('costs').doc(assessmentId);
	const [costs, loadingCosts] = useDocumentData(costsDocument);

	const revenueDocument = firestore.collection('revenue').doc(assessmentId);
	const [revenue, loadingRevenue] = useDocumentData(revenueDocument);

	const message = useMessage();

	const updateAssessment = async (data: any, setSubmitting: any) => {
		try {
			await firestore.collection('assessments').doc(assessmentId).set(
				{
					name: data.name
				},
				{ merge: true }
			);

			message.createMessage({ content: 'Saved' });
		} catch (error) {
			setSubmitting(false);

			throw new Error(error);
		}
	};

	const deleteAssessment = async (type: string) => {
		try {
			await firestore.collection(type).doc(assessmentId).delete();
		} catch (error) {
			throw new Error(error);
		}
	};

	let costsStatus = 'Not Started';
	if (costs && costs.type) {
		if (costs.type === 'both' && costs.totalCurrentTeamCosts > 0 && costs.totalFutureTeamCosts > 0) {
			costsStatus = 'Complete';
		} else if (costs.type === 'current' && costs.totalCurrentTeamCosts > 0) {
			costsStatus = 'Complete';
		} else if (costs.type === 'future' && costs.totalFutureTeamCosts > 0) {
			costsStatus = 'Complete';
		} else {
			costsStatus = 'In Progress';
		}
	}
	const revenueStatus = revenue ? (revenue?.payers?.length > 0 ? 'Complete' : 'Started') : 'Not Started';
	const summaryStatus =
		costs || revenue
			? revenueStatus === 'Complete' && costsStatus === 'Complete'
				? 'Complete'
				: 'Started'
			: 'Not Started';
	const assessments = [
		{
			type: 'costs',
			status: costsStatus,
			info: (
				<>
					<Link
						className={css.assessmentTitle}
						type='link'
						to={'/assessment/' + assessmentId + '/costs'}
					>
						<h2>Cost Analysis</h2>
					</Link>
					<ul className={css.assessmentDetails}>
						<li>
							<Link
								className={css.inlineLink}
								to={'/assessment/' + assessmentId + '/costs/current-team'}
							>
								Current Team Costs
							</Link>{' '}
							<span className={css.label}>Total:</span>
							<strong>
								{' '}
								{costs && costs.type !== 'future' ? (
									formatAsUSD(costs?.totalCurrentTeamCosts)
								) : (
									<>no data entered</>
								)}
							</strong>
						</li>
						<li>
							<Link className={css.inlineLink} to={'/assessment/' + assessmentId + '/costs/future-team'}>
								Future Team Costs
							</Link>{' '}
							<span className={css.label}>Projected Total:</span>
							<strong>
								{' '}
								{costs && costs.type !== 'current' ? (
									formatAsUSD(costs?.totalFutureTeamCosts)
								) : (
									<>no data entered</>
								)}
							</strong>
						</li>
						<li>
							<Link
								className={css.inlineLink}
								to={'/assessment/' + assessmentId + '/costs/future-other'}
							>
								Future Investments
							</Link>{' '}
							<span className={css.label}>Projected Total:</span>
							<strong>
								{' '}
								{costs && costs.type !== 'current' ? (
									formatAsUSD(costs?.totalFutureOtherCosts)
								) : (
									<>no data entered</>
								)}
							</strong>
						</li>
					</ul>
					{costs && (
						<Link
							className={css.summaryLink}
							type='link'
							to={'/assessment/' + assessmentId + '/costs/summary'}
						>
							View Detailed Summary
						</Link>
					)}
				</>
			),
			updatedAt: costs?.updatedAt
		},
		{
			type: 'revenue',
			status: revenueStatus,
			info: (
				<>
					<Link
						className={css.assessmentTitle}
						type='link'
						to={'/assessment/' + assessmentId + '/revenue'}
					>
						<h2>Revenue Analysis</h2>
					</Link>
					<ul className={css.assessmentDetails}>
						<li>
							<Link className={css.inlineLink} to={'/assessment/' + assessmentId + '/revenue/payers'}>
								Payers
							</Link>
						</li>
						<li>
							<Link
								className={css.inlineLink}
								to={'/assessment/' + assessmentId + '/revenue/utilization'}
							>
								Utilization
							</Link>
						</li>
					</ul>
					{revenue && (
						<Link
							className={css.summaryLink}
							type='link'
							to={'/assessment/' + assessmentId + '/revenue/summary'}
						>
							View Detailed Summary
						</Link>
					)}
				</>
			),
			updatedAt: revenue?.updatedAt
		},
		{
			type: 'comprehensive-summary',
			status: summaryStatus,
			info: (
				<>
					<Link
						className={css.assessmentTitle}
						type='link'
						to={'/assessment/' + assessmentId + '/comprehensive-summary'}
					>
						<h2>Comprehensive Summary</h2>
					</Link>
					<div className={css.constrainedText}>
						As you complete modules they are combined and summarized into a comprehensive summary to
						build a more complete prediction for your team.
					</div>
				</>
			),
			updatedAt: revenue?.updatedAt
		}
	];

	const { createModal, removeModal } = useModal();
	const getModalProps = (event: any, type: string) => ({
		sourceEvent: event,
		heading: 'Restart',
		Content: () => (
			<p>
				Are you sure you want to restart this {type} assessment? Doing so will clear your current values.
			</p>
		),
		primaryButtonAction: () => {
			deleteAssessment(type);
			removeModal();
		}
	});
	return (
		<>
			{!loadingAssessment && assessment && (
				<PageFrame
					title={'Assessment - ' + assessment.name}
					backLocation={{ label: 'Back to all assessments', to: '/assessments' }}
					sidebarContent={
						<>
							<strong>Assessment</strong>
							<p>
								Select a module to perform focused assessment or complete all modules for a comprehensive
								approach.
							</p>
							<p>
								Quickly edit completed modules of the assessments by clicking on underlined subtotals.
							</p>
							<p>Click “remove” to clear entries and restart the module section.</p>
						</>
					}
				>
					<Formik
						initialValues={{
							name: assessment.name
						}}
						validateOnChange={false}
						validateOnBlur={false}
						onSubmit={(data, { setSubmitting, resetForm }) => {
							setSubmitting(true);
							updateAssessment(data, setSubmitting);
						}}
						validationSchema={yup.object().shape({
							name: yup.string().required('Assessment Name is required')
						})}
					>
						{props => {
							const { isSubmitting } = props;

							return (
								<Form>
									<h1 className={classes('blue', 'h2')}>
										Assessment Name:<span className='screenReaderText'>{assessment.name}</span>
									</h1>

									<div className={css.assessmentNameSplit}>
										<Input
											label={'Update Assessment Name'}
											className={css.assessmentName}
											isLabelVisible={false}
											name={'name'}
											placeholder={'Enter Assessment Name'}
											formikProps={props}
											aria-invalid={props.errors.name && props.touched.name ? 'true' : null}
										></Input>
										<Button size='small' type='primary' htmlType='submit' isLoading={isSubmitting}>
											Save
										</Button>
									</div>
								</Form>
							);
						}}
					</Formik>
					{!loadingCosts && !loadingRevenue && (
						<Table
							idProperty='type'
							className={css.assessmentTable}
							headers={[
								{
									label: 'Module Name',
									property: 'info'
								},
								{
									label: 'Status',
									property: 'status',
									className: classes(css.smallColumn, css.columnHiddenOnMobile)
								},
								{
									label: 'Last Updated',
									property: 'updatedAt',
									renderFunction: (data: any) => {
										const date = data.updatedAt ? (data?.updatedAt?.toDate() as Date) : null;
										return <>{date?.toLocaleDateString()}</>;
									},
									className: classes(css.smallColumn, css.columnHiddenOnMobile)
								},
								{
									label: 'Manage',
									property: 'status',
									renderFunction: (data: any) => (
										<>
											{data.type === 'comprehensive-summary' || data.status === 'Not Started' ? (
												<Link
													size='small'
													type='primary'
													to={'/assessment/' + assessmentId + '/' + data.type}
												>
													{data.type === 'comprehensive-summary' ? 'View' : 'Start'}
												</Link>
											) : (
												<div className={css.manageEditView}>
													<Link type='link' to={'/assessment/' + assessmentId + '/' + data.type}>
														Edit
													</Link>
													<span className={css.spacer} aria-hidden='true'>
														{' '}
														|{' '}
													</span>
													<Button
														type='link'
														onClick={event => {
															createModal(getModalProps(event, data.type));
														}}
													>
														Restart
													</Button>
												</div>
											)}
										</>
									),
									className: css.smallColumn
								}
							]}
							// @ts-ignore
							data={assessments}
						></Table>
					)}
				</PageFrame>
			)}
		</>
	);
};
