import React from 'react';
import css from './CheckboxButton.module.scss';
import { removePropertiesFromObjects } from 'common/utils/removePropertiesFromObjects';
import classes from 'classnames';
import { RemoveProp } from 'common/types/TypeHelpers';

export type TCheckboxType = 'none' | 'partial' | 'all';

type TCheckboxButton = RemoveProp<
	React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
	'type'
> & {
	type: TCheckboxType;
	htmlType?: 'submit' | 'reset' | 'button';
};

export const CheckboxButton: React.FC<TCheckboxButton> = props => {
	const { children, className, type, htmlType } = props;

	const buttonProps = removePropertiesFromObjects(['children', 'className', 'type', 'htmlType'], props);

	return (
		<button
			{...buttonProps}
			type={htmlType}
			className={classes(css.checkboxButton, className, css[type])}
		>
			{children}
		</button>
	);
};
